import { AppScreen } from "@contentstech/stackflow-plugin-basic-ui/solid";
import { useParent } from "@contentstech/stackflow-plugin-omniflow";
import {
	type ActivityComponentType,
	useFlow,
	useStack,
} from "@contentstech/stackflow-solid/future";
import { Button as RawButton } from "@kobalte/core/button";
import {
	createForm,
	getValues,
	setError,
	submit,
	valiForm,
} from "@modular-forms/solid";
import { type JSX, Show, Suspense, createSignal } from "solid-js";
import { Dynamic } from "solid-js/web";
import * as v from "valibot";
import { Passthrough } from "~/components/passthrough";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "~/components/ui/dialog";
import { Divider } from "~/components/ui/divider";
import { FormTextField } from "~/components/ui/textField";
import { Toaster, showToast } from "~/components/ui/toast";
import { useTopAlert } from "~/components/ui/topAlert";
import { environment } from "~/lib/environment";
import { latest } from "~/lib/latest";
import { MixpanelEvent, trackEvent } from "~/lib/mixpanel";
import { session, supabase } from "~/lib/supabase";
import { cn } from "~/lib/utils";
import { PasswordRules } from "../auth/emailSignUp";

declare module "@stackflow/config" {
	interface Register {
		AccountSettingsActivity: Record<string, never>;
	}
}

const ChangePasswordForm = v.pipe(
	v.object({
		currentPassword: PasswordRules,
		newPassword: PasswordRules,
		confirmPassword: v.string(),
	}),
	v.forward(
		v.partialCheck(
			[["newPassword"], ["confirmPassword"]],
			(input) => input.newPassword === input.confirmPassword,
			"Passwords do not match",
		),
		["confirmPassword"],
	),
);
const ChangeNameForm = v.object({
	name: v.pipe(
		v.string(),
		v.maxLength(20, "Please keep it under 20 characters."),
	),
});

type ChangePasswordForm = v.InferOutput<typeof ChangePasswordForm>;
type ChangeNameForm = v.InferOutput<typeof ChangeNameForm>;

const AccountSettingsActivity: ActivityComponentType<
	"AccountSettingsActivity"
> = () => {
	const [showSignOutDialog, setShowSignOutDialog] = createSignal(false);
	const [showDeleteDialog, setShowDeleteDialog] = createSignal(false);
	const [showChangePwDialog, setShowChangePwDialog] = createSignal(false);
	const [showChangeNameDialog, setShowChangeNameDialog] = createSignal(false);
	const stack = useStack();
	const actions = useFlow();
	const topAlert = useTopAlert();
	const parent = useParent();

	const accountProvider = () =>
		session()?.user.identities?.[0]?.provider ?? "unknown";
	const name = () => latest(() => session()?.user.user_metadata.name as string);
	const email = () => latest(() => session()?.user.email);
	const signout = async () => {
		await supabase.auth.signOut({ scope: "local" });
		trackEvent(MixpanelEvent.SignOut, {});
		actions.pop(stack().activities.length - 1, { animate: false });
		actions.replace("SignUpActivity", {});
		// This is a hack to clear the cache
		// @urql/solid doesn't have a way to clear the cache manually yet (even though the client is recreated)
		// ref: https://github.com/urql-graphql/urql/issues/297
		// TODO: find a better workaround
		window.location.reload();
	};
	const [pwForm, { Form: PwForm, Field: PwField }] =
		createForm<ChangePasswordForm>({
			validate: valiForm(ChangePasswordForm),
			validateOn: "blur",
		});
	const [nameForm, { Form: NameForm, Field: NameField }] =
		createForm<ChangeNameForm>({
			validate: valiForm(ChangeNameForm),
			validateOn: "blur",
			initialValues: { name: name() },
		});

	const [revealPassword, setRevealPassword] = createSignal(false);

	return (
		<Dynamic component={parent ? Passthrough : AppScreen}>
			<div class="flex flex-col h-full env-mobile:(pb-[var(--safe-area-inset-bottom)]) env-desktop:(max-w-700px w-full)">
				<Show when={!parent}>
					<AppBar title="Account" />
				</Show>
				<div class="flex-1 flex flex-col gap-30px env-desktop:gap-50px env-mobile:(px-16px my-6px)">
					<div class="flex flex-col gap-10px env-desktop:(gap-16px)">
						<h3 class="prose-sm-b text-secondary env-desktop:(prose-unbounded-lg text-primary)">
							Sign in method
						</h3>
						<Suspense>
							<div class="flex items-center gap-12px">
								<div class="size-48px bg-white rounded-full flex items-center justify-center border-1px border-border-light">
									<i
										class="inline-block"
										classList={{
											"i-bp-google": accountProvider() === "google",
											"i-bp-apple": accountProvider() === "apple",
											"i-bp-email": accountProvider() === "email",
										}}
									/>
								</div>
								<p class="prose-sm text-primary">
									{
										{
											google: "Google",
											apple: "Apple",
											email: "Email",
											unknown: "Unknown",
										}[accountProvider()]
									}
								</p>
							</div>
						</Suspense>
					</div>
					<Show when={environment === "desktop"}>
						<div class="flex flex-col gap-16px">
							<h3 class="prose-unbounded-lg text-primary mb-16px">
								Personal details
							</h3>
							<div class="flex flex-row h-40px">
								<div class="flex-1 flex flex-col gap-6px">
									<p class="prose-sm-b text-primary">Name</p>
									<p class="prose-xs text-secondary">{name()}</p>
								</div>
								<Button
									variant="text-only"
									size="sm"
									class="self-start"
									onClick={() => setShowChangeNameDialog(true)}
								>
									Edit
								</Button>
							</div>
							<Divider class="mx-0" />
							<div class="flex flex-row h-40px">
								<div class="flex-1 flex flex-col gap-6px">
									<p class="prose-sm-b text-primary">Email address</p>
									<p class="prose-xs text-secondary">{email()}</p>
								</div>
								{/* TODO: Implement this */}
								{/* <Button variant="text-only" size="sm" class="self-start">
									Edit
								</Button> */}
							</div>
							<Show when={accountProvider() === "email"}>
								<Divider class="mx-0" />
								<div class="flex flex-row h-40px">
									<div class="flex-1 flex flex-col gap-6px">
										<p class="prose-sm-b text-primary">Password</p>
										<div class="flex gap-6px py-4px">
											{Array(8)
												.fill(null)
												.map((_, i) => (
													<div class="size-8px rounded-full bg-tertiary" />
												))}
										</div>
									</div>
									<Button
										variant="text-only"
										size="sm"
										class="self-start"
										onClick={() => setShowChangePwDialog(true)}
									>
										Change
									</Button>
								</div>
							</Show>
						</div>
					</Show>
					<Show when={environment === "mobile"}>
						<div class="flex flex-col gap-24px">
							<FormTextField
								type="text"
								readOnly
								label={
									<>
										<i class="inline-block align-text-top i-bp-user size-4 mr-6px" />
										Name
									</>
								}
								value={name()}
							/>
							<FormTextField
								type="email"
								readOnly
								label={
									<>
										<i class="inline-block align-text-top i-bp-email size-4 mr-6px" />
										Email
									</>
								}
								value={email()}
							/>
						</div>
						<div class="flex-1" />
					</Show>
					<div class="flex flex-col gap-20px env-desktop:gap-16px">
						<Show when={environment === "desktop"}>
							<h3 class="prose-unbounded-lg text-primary mb-16px">
								Manage account
							</h3>
						</Show>
						<Show
							when={
								environment === "mobile" && latest(accountProvider) === "email"
							}
						>
							<AccountAction
								icon={<i class="inline-block i-bp-password" />}
								title="Change password"
								type="normal"
								onClick={() => {
									actions.push("RequestPasswordResetActivity", {
										email: email(),
									});
								}}
								class="my-12px"
							/>
						</Show>
						<AccountAction
							icon={<i class="inline-block i-bp-sign-out" />}
							title="Sign out"
							type="normal"
							description={
								environment === "mobile"
									? "When you sign out, your data will remain on your device until you delete the app."
									: undefined
							}
							onClick={() =>
								environment === "mobile"
									? setShowSignOutDialog(true)
									: signout()
							}
						/>
						<Show when={environment === "desktop"}>
							<Divider class="mx-0" />
						</Show>
						<AccountAction
							icon={<i class="inline-block i-bp-trash" />}
							title="Delete account"
							type="destructive"
							description={
								environment === "mobile"
									? "Deleting your account is permanent and will remove all you data including your profile, channels and data."
									: undefined
							}
							onClick={() => setShowDeleteDialog(true)}
						/>
					</div>
				</div>
			</div>
			<Dialog open={showSignOutDialog()} onOpenChange={setShowSignOutDialog}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle class="text-primary">
							Are you sure you want to sign out?
						</DialogTitle>
					</DialogHeader>
					<p class="text-secondary prose-sm text-center">
						When you sign out, your data will remain on your device until you
						delete the app.
					</p>
					<DialogFooter>
						<Button onClick={signout}>Sign out</Button>
						<RawButton
							class="self-center text-tertiary underline prose-sm-b underline-offset-4px"
							onClick={() => setShowSignOutDialog(false)}
						>
							Cancel
						</RawButton>
					</DialogFooter>
				</DialogContent>
			</Dialog>
			<Dialog open={showDeleteDialog()} onOpenChange={setShowDeleteDialog}>
				<DialogContent>
					<DialogHeader
						onClose={
							environment === "desktop"
								? () => setShowDeleteDialog(false)
								: undefined
						}
					>
						<DialogTitle>
							<Show
								when={environment === "mobile"}
								fallback="‼️ Delete account?"
							>
								Do you want to delete your
								<br /> BackPac account?
							</Show>
						</DialogTitle>
					</DialogHeader>
					<p class="text-center env-desktop:text-left">
						Your account will be permanently deleted after seven days. If you
						log in again within the seven days, your account will be recovered
					</p>
					<DialogFooter class="env-desktop:(gap-26px)">
						<Button
							class="h-48px env-desktop:(h-42px) bg-functional-red-dark hover:bg-functional-red-dark"
							size="sm"
							onClick={async () => {
								try {
									const response = await supabase.rpc("delete_user");
									if (response.error) {
										return topAlert.open({
											variant: "error",
											children: (
												<div>
													<p>Failed to delete account</p>
													<p>Please try again</p>
												</div>
											),
										});
									}
									await supabase.auth.signOut();

									actions.pop(stack().activities.length - 1, {
										animate: false,
									});
									actions.replace("SignUpActivity", {});
									// This is a hack to clear the cache
									// @urql/solid doesn't have a way to clear the cache manually yet (even though the client is recreated)
									// ref: https://github.com/urql-graphql/urql/issues/297
									// TODO: find a better workaround
									window.location.reload();
								} finally {
									setShowDeleteDialog(false);
								}
							}}
						>
							{environment === "mobile" ? "Delete" : "Yes, delete"}
						</Button>
						<RawButton
							class="text-tertiary prose-sm-b env-mobile:(underline underline-offset-4px)"
							onClick={() => setShowDeleteDialog(false)}
						>
							{environment === "mobile" ? "Cancel" : "No, cancel"}
						</RawButton>
					</DialogFooter>
				</DialogContent>
			</Dialog>
			<Show when={latest(accountProvider) === "email"}>
				<Dialog
					open={showChangePwDialog()}
					onOpenChange={setShowChangePwDialog}
				>
					<DialogContent class="env-desktop:(max-w-560px)">
						<DialogHeader
							onClose={
								environment === "desktop"
									? () => setShowChangePwDialog(false)
									: undefined
							}
						>
							<DialogTitle>Change password</DialogTitle>
						</DialogHeader>
						<div class="flex flex-col gap-20px">
							<p class="prose-sm text-secondary">
								Please ensure your password is at least 12 characters long.
							</p>
							<PwForm
								class="flex flex-col text-left gap-20px"
								onSubmit={async (data) => {
									const { error: signInError } =
										await supabase.auth.signInWithPassword({
											email: email()!,
											password: data.currentPassword,
										});

									if (signInError) {
										setError(
											pwForm,
											"currentPassword",
											"Incorrect current password",
										);
										return;
									}

									const response = await supabase.auth.updateUser({
										password: data.newPassword,
									});

									if (response.error) {
										return topAlert.open({
											variant: "error",
											children: (
												<div>
													<p>Failed to change password</p>
													<p>Please try again</p>
												</div>
											),
										});
									}
									setShowChangePwDialog(false);
									topAlert.open({
										variant: "success",
										children: (
											<div>
												<p>Password change was successful!</p>
											</div>
										),
										duration: 2000,
									});
								}}
							>
								<PwField name="currentPassword">
									{(field, props) => (
										<FormTextField
											{...props}
											error={field.error}
											label={
												<>
													<i class="inline-block align-text-top i-bp-password size-4 mr-6px" />
													Current password
												</>
											}
											type={revealPassword() ? "text" : "password"}
											placeholder="Enter current password"
											actions={
												<button
													type="button"
													tabIndex={-1}
													class={cn(
														"p-2",
														revealPassword() ? "i-bp-eye" : "i-bp-eye-closed",
													)}
													onClick={() => setRevealPassword((p) => !p)}
												/>
											}
										/>
									)}
								</PwField>
								<div class="flex flex-col gap-10px">
									<PwField name="newPassword">
										{(field, props) => (
											<FormTextField
												{...props}
												error={field.error}
												label={
													<>
														<i class="inline-block align-text-top i-bp-password size-4 mr-6px" />
														Password
													</>
												}
												type={revealPassword() ? "text" : "password"}
												placeholder="Strong password"
												actions={
													<button
														type="button"
														tabIndex={-1}
														class={cn(
															"p-2",
															revealPassword() ? "i-bp-eye" : "i-bp-eye-closed",
														)}
														onClick={() => setRevealPassword((p) => !p)}
													/>
												}
											/>
										)}
									</PwField>
									<PwField name="confirmPassword">
										{(field, props) => (
											<FormTextField
												{...props}
												error={field.error}
												type={revealPassword() ? "text" : "password"}
												placeholder="Confirm password"
												actions={
													<button
														type="button"
														tabIndex={-1}
														class={cn(
															"p-2",
															revealPassword() ? "i-bp-eye" : "i-bp-eye-closed",
														)}
														onClick={() => setRevealPassword((p) => !p)}
													/>
												}
											/>
										)}
									</PwField>
								</div>
							</PwForm>
						</div>
						<DialogFooter>
							<Button
								class="h-48px env-desktop:(h-42px)"
								size="sm"
								variant="highlighted"
								onClick={() => submit(pwForm)}
								disabled={
									!v.safeParse(ChangePasswordForm, getValues(pwForm)).success ||
									pwForm.submitting
								}
							>
								Change password
							</Button>
							<Button
								variant="text"
								size="sm"
								class="self-center text-tertiary env-desktop:(after:h-0! h-42px)"
								onClick={() => setShowChangePwDialog(false)}
							>
								Cancel
							</Button>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			</Show>
			<Dialog
				open={showChangeNameDialog()}
				onOpenChange={setShowChangeNameDialog}
			>
				<DialogContent class="env-desktop:(max-w-360px)">
					<DialogHeader
						onClose={
							environment === "desktop"
								? () => setShowChangeNameDialog(false)
								: undefined
						}
					>
						<DialogTitle>Change nickname</DialogTitle>
					</DialogHeader>
					<div class="flex flex-col gap-20px">
						<NameForm
							class="flex flex-col text-left gap-20px"
							onSubmit={async (data) => {
								const response = await supabase.auth.updateUser({
									data: {
										name: data.name,
									},
								});

								if (response.error) {
									return topAlert.open({
										variant: "error",
										children: (
											<div>
												<p>Failed to change name</p>
												<p>Please try again</p>
											</div>
										),
									});
								}
								await supabase.auth.refreshSession();
								setShowChangeNameDialog(false);
								showToast({
									message: (
										<div class="flex items-center gap-10px">
											<i class="i-bp-info size-20px text-white" />
											<p class="text-white text-sm-b leading-[1.2]">
												Name change was successful!
											</p>
										</div>
									),
									duration: 5000,
								});
							}}
						>
							<NameField name="name" validateOn="change">
								{(field, props) => (
									<FormTextField
										{...props}
										error={field.error}
										value={field.value}
										label={
											<>
												<i class="inline-block align-text-top i-bp-user size-4 mr-6px" />
												Name
											</>
										}
										type="text"
										placeholder="Your name"
										description="Enter up to 20 characters"
										bottomSuffix={<span>{field.value?.length ?? 0}/20</span>}
									/>
								)}
							</NameField>
						</NameForm>
					</div>
					<DialogFooter>
						<Button
							class="h-42px w-120px text-primary"
							size="sm"
							variant="highlighted"
							onClick={() => submit(nameForm)}
							disabled={
								!v.safeParse(ChangeNameForm, getValues(nameForm)).success ||
								nameForm.submitting
							}
						>
							Change
						</Button>
						<Button
							variant={environment === "mobile" ? "text" : "text-only"}
							size="sm"
							class="self-center text-tertiary env-desktop:h-42px"
							onClick={() => setShowChangeNameDialog(false)}
						>
							Cancel
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
			<Toaster class="transition-all bottom-auto top-80px w-364px left-1/2 -translate-x-1/2 z-13" />
		</Dynamic>
	);
};

type AccountActionProps = {
	icon: JSX.Element;
	title: JSX.Element;
	type: "normal" | "destructive";
	description?: JSX.Element;
	onClick: () => void;
	class?: string;
};

const AccountAction = (props: AccountActionProps) => {
	return (
		<div class={cn("flex flex-col gap-10px", props.class)}>
			<RawButton onClick={props.onClick}>
				<div
					class="flex items-center gap-12px"
					classList={{
						"text-primary": props.type === "normal",
						"text-functional-red": props.type === "destructive",
					}}
				>
					<div class="size-24px [&>i]:size-full">{props.icon}</div>
					<span class="prose-sm">{props.title}</span>
				</div>
			</RawButton>
			<Show when={props.description}>
				{(desc) => <p class="prose-xs text-tertiary">{desc()}</p>}
			</Show>
		</div>
	);
};

export default AccountSettingsActivity;
