import type { Component, ComponentProps, JSX, ValidComponent } from "solid-js";
import { Show, splitProps } from "solid-js";

import type {
	ContentProps,
	DescriptionProps,
	DynamicProps,
	LabelProps,
	OverlayProps,
} from "@corvu/drawer";
import DrawerPrimitive from "@corvu/drawer";

import { type VariantProps, cva } from "class-variance-authority";
import { cn } from "~/lib/utils";

const Drawer = DrawerPrimitive;

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

type DrawerOverlayProps<T extends ValidComponent = "div"> = OverlayProps<T> & {
	class?: string;
};

const DrawerOverlay = <T extends ValidComponent = "div">(
	props: DynamicProps<T, DrawerOverlayProps<T>>,
) => {
	const [, rest] = splitProps(props as DrawerOverlayProps, ["class"]);
	const drawerContext = DrawerPrimitive.useContext();
	return (
		<DrawerPrimitive.Overlay
			class={cn(
				"fixed inset-0 z-50 data-[transitioning]:transition-colors data-[transitioning]:duration-300",
				props.class,
			)}
			style={{
				"background-color": `rgb(0 0 0 / ${0.3 * Math.min(1, drawerContext.openPercentage())})`,
			}}
			{...rest}
		/>
	);
};

type DrawerContentProps<T extends ValidComponent = "div"> = ContentProps<T> & {
	class?: string;
	children?: JSX.Element;
	full?: boolean;
};

const DrawerContent = <T extends ValidComponent = "div">(
	props: DynamicProps<T, DrawerContentProps<T>>,
) => {
	const [locals, rest] = splitProps(props as DrawerContentProps, [
		"class",
		"children",
		"full",
	]);
	return (
		<DrawerPortal>
			<DrawerOverlay />
			<DrawerPrimitive.Content
				class={cn(
					"fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-4 bg-white after:absolute after:inset-x-0 after:top-full after:h-1/2 after:bg-inherit after:content-empty data-[transitioning]:transition-transform data-[transitioning]:duration-300",
					locals.class,
				)}
				{...rest}
			>
				<div class="mx-auto mt-10px h-1 w-36px rounded-full bg-border-light" />
				{locals.children}
				<Show when={!locals.full}>
					<div class="h-[var(--safe-area-inset-bottom)]" />
				</Show>
			</DrawerPrimitive.Content>
		</DrawerPortal>
	);
};

const DrawerHeader: Component<ComponentProps<"div">> = (props) => {
	const [, rest] = splitProps(props, ["class"]);
	return (
		<div
			class={cn(
				"flex flex-col gap-1 p-4 text-center border-b border-b-border-light",
				props.class,
			)}
			{...rest}
		/>
	);
};

const DrawerFooter: Component<ComponentProps<"div">> = (props) => {
	const [, rest] = splitProps(props, ["class"]);
	return (
		<div class={cn("t-auto flex flex-col gap-2 p-4", props.class)} {...rest} />
	);
};

const drawerTitleVariants = cva("text-primary leading-none tracking-tight", {
	variants: {
		variant: {
			default: "prose-md-b",
			light: "prose-sm",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

type DrawerTitleProps<T extends ValidComponent = "div"> = LabelProps<T> &
	VariantProps<typeof drawerTitleVariants> & { class?: string };

const DrawerTitle = <T extends ValidComponent = "div">(
	props: DynamicProps<T, DrawerTitleProps<T>>,
) => {
	const [local, rest] = splitProps(props as DrawerTitleProps, [
		"variant",
		"class",
	]);
	return (
		<DrawerPrimitive.Label
			class={cn(drawerTitleVariants({ variant: local.variant }), local.class)}
			{...rest}
		/>
	);
};

type DrawerDescriptionProps<T extends ValidComponent = "div"> =
	DescriptionProps<T> & {
		class?: string;
	};

const DrawerDescription = <T extends ValidComponent = "div">(
	props: DynamicProps<T, DrawerDescriptionProps<T>>,
) => {
	const [, rest] = splitProps(props as DrawerDescriptionProps, ["class"]);
	return (
		<DrawerPrimitive.Description
			class={cn("text-tertiary prose-caption", props.class)}
			{...rest}
		/>
	);
};

export {
	Drawer,
	DrawerPortal,
	DrawerOverlay,
	DrawerTrigger,
	DrawerClose,
	DrawerContent,
	DrawerHeader,
	DrawerFooter,
	DrawerTitle,
	DrawerDescription,
};
