import { AppScreen } from "@contentstech/stackflow-plugin-basic-ui/solid";
import { useChild, useParent } from "@contentstech/stackflow-plugin-omniflow";
import {
	type ActivityComponentType,
	useFlow,
} from "@contentstech/stackflow-solid/future";
import { Button as RawButton } from "@kobalte/core/button";
import { type JSXElement, Show, createMemo } from "solid-js";
import { Dynamic } from "solid-js/web";
import { Passthrough } from "~/components/passthrough";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "~/components/ui/dialog";
import { environment } from "~/lib/environment";

declare module "@stackflow/config" {
	interface Register {
		PayoutSettingsActivity: Record<string, never>;
	}
}

const PayoutSettingsActivity: ActivityComponentType<
	"PayoutSettingsActivity"
> = () => {
	const actions = useFlow();
	const parent = useParent();
	const child = useChild();
	const hasChild = createMemo(() => !!child?.());
	const latestChild = createMemo<JSXElement>(
		(prev) => child?.()?.render() ?? prev,
	);
	return (
		<Dynamic component={parent ? Passthrough : AppScreen}>
			<div class="flex flex-col h-full pb-[var(--safe-area-inset-bottom)] env-desktop:(max-w-700px w-full)">
				<Show when={environment === "mobile"}>
					<AppBar title="Setting" />
				</Show>
				<div class="flex-1 flex flex-col gap-30px env-desktop:gap-50px env-mobile:(px-16px my-6px)">
					<div class="flex flex-col gap-10px env-desktop:(gap-16px)">
						<h3 class="prose-sm-b text-secondary env-desktop:(prose-unbounded-lg text-primary) mb-16px">
							Payout
						</h3>
						<div class="flex flex-row h-40px">
							<RawButton
								class="flex-1 flex flex-col gap-6px cursor-pointer"
								onClick={() => actions.push("PayoutDetailsActivity", {})}
							>
								<p class="prose-sm-b text-primary">Payment Details</p>
								<p class="prose-xs text-secondary">
									Update the payment method and billing address on file
								</p>
							</RawButton>
							<Button
								variant="text"
								size="sm"
								class="after:h-0! text-tertiary self-center pr-0!"
								onClick={() => actions.push("PayoutDetailsActivity", {})}
							>
								<i class="inline-block i-bp-chevron-right" />
							</Button>
						</div>
						{/* Uncomment when invoice is ready */}
						{/* <Divider class="mx-0" />
						<div class="flex flex-row h-40px">
							<div class="flex-1 flex flex-col gap-6px">
								<p class="prose-sm-b text-primary">Invoice Details</p>
								<p class="prose-xs text-secondary">
									Update the legal company name and address to appear on your
									invoice
								</p>
							</div>
							<Button
								variant="text"
								size="sm"
								class="after:h-0! text-tertiary self-center pr-0!"
							>
								<i class="inline-block i-bp-chevron-right" />
							</Button>
						</div>
						<Divider class="mx-0" />
						<div class="flex flex-row h-40px">
							<div class="flex-1 flex flex-col gap-6px">
								<p class="prose-sm-b text-primary">Invoices</p>
								<p class="prose-xs text-secondary">Get invoices via email</p>
							</div>
							<ToggleSwitch class="self-center" defaultChecked />
						</div> */}
					</div>
				</div>
			</div>
			<Show when={child}>
				<Dialog open={hasChild()}>
					<DialogContent class="env-desktop:(max-h-800px overflow-y-auto pt-0)">
						<DialogHeader
							onClose={() => actions.pop()}
							class="sticky top-0 pt-20px pb-10px bg-white z-30"
						>
							<DialogTitle class="text-primary">Payout details</DialogTitle>
						</DialogHeader>
						{latestChild()}
					</DialogContent>
				</Dialog>
			</Show>
		</Dynamic>
	);
};

export default PayoutSettingsActivity;
