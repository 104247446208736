import {
	Show,
	Suspense,
	createEffect,
	createMemo,
	createSignal,
} from "solid-js";
import { MusicListItemFragment } from "~/components/musicListItem";
import { Image } from "~/components/ui/image";
import { Skeleton } from "~/components/ui/skeleton";
import { WebLink } from "~/components/webLink";
// import { MusicListItemFragment } from "~/components/musicListItem";
import { type FragmentOf, graphql, readFragment } from "~/lib/gql/tada";
import { cn } from "~/lib/utils";

export const ChannelVideoItemFragment = graphql(
	`
		fragment ChannelVideoItem on ChannelVideo {
			id
			music {
				...MusicListItem
			}
			metrics {
				from
				to
				totalViews {
					aggregate {
						sum
					}
				}
				premiumSubscriberViews {
					aggregate {
						sum
					}
				}
				estimatedPayouts {
					aggregate {
						sum
					}
				}
				rpm {
					aggregate {
						mean
					}
				}
			}
		}
	`,
	[MusicListItemFragment],
);

type YoutubeVideoInfo = {
	id: string;
	title: string;
	thumbnail_url: string;
};

type ChannelVideoCardProps = {
	$video: FragmentOf<typeof ChannelVideoItemFragment> | undefined;
	class?: string;
};

export const ChannelContentCard = (props: ChannelVideoCardProps) => {
	// TODO: Replace this with a server-side data
	const video = createMemo(() =>
		readFragment(ChannelVideoItemFragment, props.$video),
	);
	const music = createMemo(() =>
		readFragment(MusicListItemFragment, video()?.music),
	);
	const [videoInfo, setVideoInfo] = createSignal<YoutubeVideoInfo | undefined>(
		undefined,
	);

	// TODO: This is a hacky workaround to roll out quickly.
	// it'd be better to get the information from its server
	createEffect(() => {
		if (!video()) return;
		const oembedUrl = `https://www.youtube.com/oembed?url=https://www.youtube.com/shorts/${video()!.id}`;
		fetch(oembedUrl)
			.then((res) => res.json())
			.then((data) => {
				setVideoInfo(data);
			});
	});

	const totalViews = createMemo(
		() => video()?.metrics.totalViews.aggregate.sum,
	);
	const premiumSubscriberViews = createMemo(
		() => video()?.metrics.premiumSubscriberViews.aggregate.sum,
	);
	const rpm = createMemo(() => video()?.metrics.rpm.aggregate.mean);
	const estimatedPayouts = createMemo(
		() => video()?.metrics.estimatedPayouts.aggregate.sum,
	);
	const premiumRatio = createMemo(() => {
		const _psviews = premiumSubscriberViews();
		const _totalviews = totalViews();
		if (!_psviews || !_totalviews) return 0;
		return (_psviews / _totalviews) * 100;
	});

	return (
		<div
			class={cn(
				"flex flex-col p-16px rounded-10px bg-white border border-[0.75px] border-border-light gap-16px",
				props.class,
			)}
		>
			<div class="flex gap-16px">
				<Suspense fallback={<Skeleton width="80px" height="120px" />}>
					<Show
						when={videoInfo()?.thumbnail_url}
						fallback={<Skeleton width="80px" height="120px" />}
					>
						{(thumbnail_url) => (
							<WebLink href={`https://www.youtube.com/shorts/${video()?.id}`}>
								<Image
									src={thumbnail_url()}
									alt="cover"
									class="w-80px h-120px rounded-4px object-cover bg-[50%] bg-lightgray bg-no-repeat"
								/>
							</WebLink>
						)}
					</Show>
				</Suspense>
				<div class="flex flex-col flex-1 min-w-0">
					<h3 class="prose-sm mt-4px line-clamp-2">
						{videoInfo()?.title ?? "-"}
					</h3>
					{/** TODO: Add the datetime. Currently, the youtube API doesn't provide the date*/}
					{/* <h4 class="prose-xs text-secondary mt-6px">{" "}</h4> */}
					<div class="flex gap-8px items-center mt-16px">
						<Suspense fallback={<Skeleton width="32px" height="32px" />}>
							<Show
								when={music()?.coverImage}
								fallback={<Skeleton width="32px" height="32px" />}
							>
								{(coverImage) => (
									<Image
										src={new URL(
											coverImage(),
											import.meta.env.VITE_API_BASE,
										).toString()}
										alt="music"
										class="size-32px rounded-3px"
									/>
								)}
							</Show>
						</Suspense>
						<span class="prose-xs line-clamp-2">{music()?.title.en}</span>
					</div>
				</div>
			</div>
			<div class="flex flex-col gap-10px">
				<div class="flex justify-between items-center">
					<span class="prose-xs text-secondary">Total Views</span>
					<span class="prose-xs-b">
						{totalViews()?.toLocaleString() ?? "-"}
					</span>
				</div>
				<div class="flex justify-between items-center">
					<span class="prose-xs text-secondary">Est. payouts</span>
					<span class="prose-xs-b">
						${estimatedPayouts()?.toFixed(2) ?? "-"}
					</span>
				</div>
				<div class="flex justify-between items-center">
					<span class="prose-xs text-secondary">Premium sub. views</span>
					<span class="prose-xs-b">
						{premiumSubscriberViews()?.toLocaleString() ?? "-"}
					</span>
				</div>
				<div class="flex justify-between items-center">
					<span class="prose-xs text-secondary">Premium rate</span>
					<span class="prose-xs-b">{premiumRatio()?.toFixed(2) ?? "-"}</span>
				</div>
				<div class="flex justify-between items-center">
					<span class="prose-xs text-secondary">RPM</span>
					<span class="prose-xs-b">${rpm()?.toFixed(2) ?? "-"}</span>
				</div>
			</div>
		</div>
	);
};
