import "@formatjs/intl-durationformat/polyfill";

import { Provider as UrqlProvider } from "@urql/solid";
import { Suspense } from "solid-js";
import { ErrorBoundary } from "./components/errorBoundary";
import { TopAlertProvider } from "./components/ui/topAlert";
import { urqlClient } from "./lib/gql/urql";
import { KeyboardProvider, useKeyboard } from "./lib/keyboard";
import { Stack } from "./stackflow";

// Check entry-server.tsx for preloading
// Note. If you change the font here, you must also change it in entry-server.tsx
import "@fontsource-variable/inter";
import "@fontsource-variable/unbounded";

import "@contentstech/stackflow-plugin-basic-ui/index.css";
import "@unocss/reset/tailwind.css";
import "uno.css";
import "./styles/global.css";

export default function App() {
	return (
		<ErrorBoundary>
			<Suspense>
				<UrqlProvider value={urqlClient}>
					<TopAlertProvider>
						<KeyboardProvider>
							{(() => {
								const keyboard = useKeyboard();
								return (
									<div
										class="relative"
										style={{
											height: `calc(100dvh - ${keyboard.height()}px)`,
										}}
									>
										<Stack transition={false} />
									</div>
								);
							})()}
						</KeyboardProvider>
					</TopAlertProvider>
				</UrqlProvider>
			</Suspense>
		</ErrorBoundary>
	);
}
