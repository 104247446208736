import type { ActivityComponentType } from "@contentstech/stackflow-solid/future";
import { Button as RawButton } from "@kobalte/core/button";
import { Select } from "@kobalte/core/select";
import { Tabs } from "@kobalte/core/tabs";
import {
	For,
	Show,
	batch,
	createEffect,
	createMemo,
	createRenderEffect,
	createSignal,
	startTransition,
} from "solid-js";
import {
	MusicActionsDrawer,
	MusicActionsDrawerFragment,
	MusicListItem,
	MusicListItemFragment,
} from "~/components/musicListItem";
import { MusicPlayer, MusicPlayerFragment } from "~/components/musicPlayer";
import { StickyHeaderContainer } from "~/components/stickyHeaderContainer";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import {
	Drawer,
	DrawerContent,
	DrawerHeader,
	DrawerTitle,
} from "~/components/ui/drawer";
import { Skeleton } from "~/components/ui/skeleton";
import { Toaster } from "~/components/ui/toast";
import { ToggleSwitch } from "~/components/ui/toggleSwitch";
import { createVirtualizer } from "~/lib/createVirtualizer";
import { environment } from "~/lib/environment";
import { createToeQuery } from "~/lib/gql/createToeQuery";
import { type FragmentOf, graphql } from "~/lib/gql/tada";
import { latest } from "~/lib/latest";
import { MixpanelEvent, trackEvent } from "~/lib/mixpanel";
import { cn } from "~/lib/utils";
import { type Mood, MoodColors, Moods } from "~/types/music";
import { useNavContext } from ".";

const PAGE_SIZE = 10;
const ITEM_HEIGHT = {
	mobile: 48,
	desktop: 76,
}[environment];
const GAP = 16;

declare module "@stackflow/config" {
	interface Register {
		MusicActivity: Record<string, never>;
	}
}

export const MusicNodeFragment = graphql(
	`
		fragment MusicNode on Music @_unmask {
			id
			...MusicListItem
			...MusicActionsDrawer
			...MusicPlayer
		}
	`,
	[MusicListItemFragment, MusicActionsDrawerFragment, MusicPlayerFragment],
);

// TODO: move these somewhere colocated after moving to Relay
// Currently it's not possible to move this out due to cyclic dependency issue
export const FavoriteMusicMutation = graphql.persisted(
	"sha256:486d95a2fe2c78216fe01ff2a4f556a33a61f55b4cd8929a8f911d6836355043",
	graphql(
		`
			mutation FavoriteMusic($id: ID!) {
				mutate: favoriteMusic(input: { musicId: $id }) {
					... on FavoriteMusicPayload {
						music {
							id
							isFavorite
							...MusicNode
						}
					}
				}
			}
		`,
		[MusicNodeFragment],
	),
);

export const UnfavoriteMusicMutation = graphql.persisted(
	"sha256:a9f35f5809ab4b5811081c82883a6bd95d8c5a1ed09085760efef2fd03c08324",
	graphql(
		`
		mutation UnfavoriteMusic($id: ID!) {
			mutate: unfavoriteMusic(input: { musicId: $id }) {
				... on UnfavoriteMusicPayload {
					music {
						id
						isFavorite
					}
				}
			}
		}
	`,
	),
);

export const MusicQuery = graphql.persisted(
	"sha256:04fc80e65a37c3cfdfdadbedb76e8208147e9a897e3b9fba0ebde2240cd3f229",
	graphql(
		`
			query Music($first: Int!, $after: String, $mood: Mood) {
				musics(
					first: $first
					after: $after
					filter: { mood: $mood }
				) {
					edges {
						node {
							...MusicNode
						}
					}
					pageInfo {
						hasNextPage
						endCursor
					}
				}
				moods {
					mood
					musicCount
				}
			}
		`,
		[MusicNodeFragment],
	),
);

export const FavoritesQuery = graphql.persisted(
	"sha256:473e3ac7a3007fdd8db61b5cca9d06c6b78e44ec5ca07c0cbbc870060e3a5ce0",
	graphql(
		`
			query Favorites($first: Int!, $after: String, $mood: Mood) {
				musics(first: $first, after: $after, filter: { favorites: true, mood: $mood }) {
					edges {
						node {
							...MusicNode
						}
					}
					pageInfo {
						hasNextPage
						endCursor
					}
				}
			}
		`,
		[MusicNodeFragment],
	),
);

// Persisted until full page reload
const [selectedMood, setSelectedMood] = createSignal<Mood | null>(null);
enum MusicTab {
	TRACKS = "Tracks",
	FAVORITES = "Favorites",
}

const MusicActivity: ActivityComponentType<"MusicActivity"> = () => {
	const nav = useNavContext();

	const [selectedMusic, setSelectedMusic] = createSignal<
		{ id: string } & FragmentOf<typeof MusicPlayerFragment>
	>();
	const [drawerMusic, setDrawerMusic] = createSignal<
		{ id: string } & FragmentOf<typeof MusicActionsDrawerFragment>
	>();
	const [musicDrawerOpen, setMusicDrawerOpen] = createSignal(false);
	const [moodOptionsOpen, setMoodOptionsOpen] = createSignal(false);
	const [selectedTab, setSelectedTab] = createSignal<MusicTab>(MusicTab.TRACKS);

	const [isPlaying, setIsPlaying] = createSignal(false);

	const [after, setAfter] = createSignal<string | undefined>(undefined);
	const [favoritesAfter, setFavoritesAfter] = createSignal<string | undefined>(
		undefined,
	);
	const [tracksQuery] = createToeQuery({
		query: MusicQuery,
		variables: () => ({
			first: PAGE_SIZE,
			after: after(),
			mood: selectedMood(),
		}),
	});

	const [favoritesQuery] = createToeQuery({
		query: FavoritesQuery,
		variables: () => ({
			first: PAGE_SIZE,
			after: favoritesAfter(),
			mood: selectedMood(),
		}),
	});

	const currentQuery = createMemo(() =>
		selectedTab() === MusicTab.FAVORITES ? favoritesQuery : tracksQuery,
	);

	const itemCount = createMemo(
		() => latest(() => currentQuery().data)?.musics?.edges.length ?? 0,
	);

	const [scrollContainer, setScrollContainer] = createSignal<HTMLElement>();
	const {
		scrollContainerRef: scrollContainerRefForVirtualizer,
		headerRef,
		contentHeight,
		visibleItems,
	} = createVirtualizer({
		itemCount,
		skeletonCountBase: 10,
		size: ITEM_HEIGHT,
		gap: environment === "mobile" ? GAP : 0,
		overscan: 10,
		hasMoreContents: () => {
			const data = latest(() => currentQuery().data);
			return !data || !!data.musics.pageInfo.hasNextPage;
		},
		onSkeletonReached: () => {
			startTransition(() => {
				const query = currentQuery();
				const cursor = query.data?.musics?.pageInfo?.endCursor;
				if (!query.fetching && cursor) {
					switch (selectedTab()) {
						case MusicTab.FAVORITES:
							setFavoritesAfter(cursor);
							break;
						case MusicTab.TRACKS:
							setAfter(cursor);
							break;
						default:
							throw new Error("Unknown tab");
					}
				}
			});
		},
	});

	type MoodEntries = [Mood, (typeof Moods)[Mood]][];
	const getMoodCount = createMemo(() => {
		const data = latest(() => tracksQuery.data);
		if (!data) return;
		const dict = Object.fromEntries(
			data.moods.map(({ mood, musicCount }) => [mood, musicCount]),
		);
		return (mood: Mood) => dict[mood] ?? 0;
	});

	const makeOnPlay = (delta: -1 | 1) => () => {
		const edges = latest(() => currentQuery().data)?.musics.edges;
		if (!edges) return;
		const selected = selectedMusic();
		if (!selected) return;
		const currentIndex = edges.findIndex(
			(edge) => edge.node.id === selected.id,
		);
		const next =
			currentIndex !== -1
				? edges.at((currentIndex + delta) % edges.length)
				: edges.at(delta === -1 ? -1 : 0);
		if (next) setSelectedMusic(next.node);
	};

	createEffect(() => {
		if (selectedMusic() === undefined) return;
		trackEvent(
			isPlaying() ? MixpanelEvent.PlayMusic : MixpanelEvent.StopMusic,
			{
				music_id: selectedMusic()?.id,
			},
		);
	});

	createRenderEffect(() => {
		void selectedMood();
		scrollContainer()?.scrollTo(0, 0);
	});

	createEffect(() => {
		selectedTab();
		setAfter(undefined);
		setFavoritesAfter(undefined);
	});

	return (
		<>
			<StickyHeaderContainer
				ref={(el) => {
					scrollContainerRefForVirtualizer(el);
					setScrollContainer(el);
				}}
				headerWrapperRef={headerRef}
				header={(hiddenAreaRef) => (
					<>
						<div ref={hiddenAreaRef}>
							<AppBar
								title="Music"
								actions={[
									<Show when={environment === "mobile"}>
										<ToggleSwitch
											class="self-center"
											checked={selectedTab() === MusicTab.FAVORITES}
											onChange={(checked) =>
												setSelectedTab(
													checked ? MusicTab.FAVORITES : MusicTab.TRACKS,
												)
											}
											thumb={{
												renderChild: (checked) => (
													<i
														class={`inline-block i-bp-heart-fill size-12px ${checked ? "bg-red" : "bg-border-dark"}`}
													/>
												),
											}}
										/>
									</Show>,
								]}
							/>
							<Show when={environment === "desktop"}>
								<Tabs
									class="mt-16px"
									value={selectedTab()}
									onChange={(value) => setSelectedTab(value as MusicTab)}
								>
									<Tabs.List class="flex border-b border-border-light relative">
										{Object.values(MusicTab).map((tab) => (
											<Tabs.Trigger
												value={tab}
												class="mx-32px py-12px prose-md-b text-tertiary data-[selected]:text-primary hover:text-secondary focus:outline-none"
											>
												{tab}
											</Tabs.Trigger>
										))}
										<Tabs.Indicator class="absolute bottom-0 bg-blue-600 transition-all duration-300 bg-primary h-2px" />
									</Tabs.List>
								</Tabs>
							</Show>
						</div>
						<div class="flex gap-10px items-center env-mobile:(px-16px py-10px h-56px) env-desktop:(px-32px py-16px h-68px)">
							<Show when={getMoodCount()} fallback={<Skeleton width={90} />}>
								{(getMoodCount) => (
									<>
										<Show when={selectedMood()}>
											<Button
												variant="offWhite"
												size="circular"
												onClick={() => {
													batch(() => {
														setAfter(undefined);
														setFavoritesAfter(undefined);
														setSelectedMood(null);
													});
												}}
											>
												<i class="inline-block i-bp-close text-primary" />
											</Button>
										</Show>
										<Select<Mood>
											open={moodOptionsOpen()}
											onOpenChange={setMoodOptionsOpen}
											options={Object.keys(Moods) as Mood[]}
											value={selectedMood()}
											onChange={(mood) =>
												batch(() => {
													setAfter(undefined);
													setFavoritesAfter(undefined);
													setSelectedMood(mood);
												})
											}
											placeholder="Mood"
											placement="bottom-start"
											fitViewport
											itemComponent={(props) => (
												<Select.Item
													item={props.item}
													class="px-16px py-12px flex justify-between items-center hover:bg-background-dark"
												>
													<Select.ItemLabel class="flex items-center gap-10px">
														<i
															class={cn(
																"inline-block",
																Moods[props.item.rawValue].icon,
															)}
														/>
														<div class="flex items-center gap-6px prose-sm">
															<span class="text-primary">
																{Moods[props.item.rawValue].title}
															</span>
															<span class="text-tertiary">
																{getMoodCount()(
																	props.item.rawValue,
																).toLocaleString()}
															</span>
														</div>
													</Select.ItemLabel>
													<Select.ItemIndicator class="size-24px">
														<i class="inline-block i-bp-check-full size-24px" />
													</Select.ItemIndicator>
												</Select.Item>
											)}
										>
											<Select.Trigger<typeof Button<"button">>
												as={Button}
												variant={selectedMood() ? "default" : "offWhite"}
												size="sm"
											>
												<Select.Value<Mood>>
													{(state) => (
														<div
															class="flex items-center gap-1"
															style={{
																color:
																	MoodColors[
																		Moods[state.selectedOption()].color
																	],
															}}
														>
															<i
																class={cn(
																	"inline-block size-16px",
																	Moods[state.selectedOption()].icon,
																)}
															/>
															{Moods[state.selectedOption()].title}
														</div>
													)}
												</Select.Value>
												<Select.Icon class="inline-block i-bp-dropdown" />
											</Select.Trigger>
											<Show when={environment === "desktop"}>
												<Select.Portal>
													<Select.Content class="z-999 w-260px max-h-400px bg-white rounded-10px flex flex-col py-4px shadow-popover">
														<div class="p-12px border-b border-border-light flex items-center justify-between">
															<span class="prose-md-b text-primary">Mood</span>
															<RawButton
																class="size-32px flex items-center justify-center rounded-6px hover:bg-background-dark"
																onClick={() => setMoodOptionsOpen(false)}
															>
																<i class="inline-block i-bp-close size-16px text-primary" />
															</RawButton>
														</div>
														<Select.Listbox class="py-8px overflow-y-auto min-h-0 no-scrollbar" />
													</Select.Content>
												</Select.Portal>
											</Show>
										</Select>
										<Show when={environment === "mobile"}>
											<Drawer
												open={moodOptionsOpen()}
												onOpenChange={setMoodOptionsOpen}
											>
												<DrawerContent full>
													<DrawerHeader>
														<DrawerTitle>Mood</DrawerTitle>
													</DrawerHeader>
													<ul class="flex-1 max-h-70dvh overflow-y-auto no-scrollbar flex flex-col py-10px mb-[var(--safe-area-inset-bottom)]">
														<For each={Object.entries(Moods) as MoodEntries}>
															{([mood, { title, icon }]) => (
																<li>
																	<RawButton
																		class="flex w-full gap-10px items-center px-20px h-58px"
																		onClick={() => {
																			setMoodOptionsOpen(false);
																			batch(() => {
																				setAfter(undefined);
																				setFavoritesAfter(undefined);
																				setSelectedMood(mood);
																			});
																		}}
																	>
																		<i class={cn("inline-block", icon)} />
																		<div class="flex items-center gap-6px">
																			<div class="prose-md text-primary">
																				{title}
																			</div>
																			<div class="prose-sm text-tertiary">
																				{getMoodCount()(mood).toLocaleString()}
																			</div>
																		</div>
																		<div class="flex-1" />
																		<Show when={selectedMood() === mood}>
																			<i class="inline-block i-bp-check-full text-primary" />
																		</Show>
																	</RawButton>
																</li>
															)}
														</For>
													</ul>
												</DrawerContent>
											</Drawer>
										</Show>
									</>
								)}
							</Show>
						</div>
						<Show when={environment === "desktop"}>
							<div class="grid grid-cols-[56px_3fr_3fr_4fr_1fr_56px_56px] gap-4 flex-grow-1 px-32px py-16px border-y border-border-light prose-xs-b text-secondary">
								<div class="col-span-2">Track</div>
								<div>Mood</div>
								{/* TODO: revert this when soundbar is ready */}
								{/* <div class="hidden">Soundbar</div> */}
								<div />
								<div>Duration</div>
								<div class="text-center">Favorite</div>
								<div class="text-center">Use</div>
							</div>
						</Show>
					</>
				)}
			>
				<div
					class="relative transition-opacity env-mobile:my-10px"
					style={{
						"margin-bottom": `calc(var(--safe-area-inset-bottom) + ${
							nav.size.height ?? 0
						}px + 38px)`,
						height: `${contentHeight()}px`,
					}}
				>
					<For each={visibleItems()}>
						{(item) => (
							<Show
								when={
									latest(() => currentQuery().data)?.musics?.edges[item.index]
										?.node
								}
								fallback={
									<MusicListItem
										class="absolute top-0 left-0 w-full"
										style={{ transform: `translateY(${item.top}px)` }}
										onPlayingChange={() => {}}
									/>
								}
							>
								{(music) => (
									<MusicListItem
										$music={music()}
										class="absolute top-0 left-0 w-full"
										style={{ transform: `translateY(${item.top}px)` }}
										isSelected={selectedMusic()?.id === music().id}
										isPlaying={isPlaying()}
										onSelect={() => setSelectedMusic(music())}
										onActionsClick={() => {
											setDrawerMusic(music());
											setMusicDrawerOpen(true);
										}}
										onPlayingChange={setIsPlaying}
									/>
								)}
							</Show>
						)}
					</For>
				</div>
				<Show when={drawerMusic()}>
					{(music) => (
						<MusicActionsDrawer
							$music={music()}
							isOpen={musicDrawerOpen()}
							onOpenchange={setMusicDrawerOpen}
						/>
					)}
				</Show>
			</StickyHeaderContainer>
			<Show when={selectedMusic()}>
				<MusicPlayer
					$music={selectedMusic()}
					isPlaying={isPlaying()}
					onPlayingChange={setIsPlaying}
					onPrev={makeOnPlay(-1)}
					onNext={makeOnPlay(1)}
				/>
			</Show>
			<Toaster
				class="transition-all env-desktop:(top-94px flex flex-col items-center z-20)"
				regionProps={{
					swipeDirection: environment === "mobile" ? "left" : "up",
					pauseOnInteraction: false,
					limit: 1,
				}}
				style={{
					bottom:
						environment === "mobile"
							? `calc(var(--safe-area-inset-bottom) + ${
									(nav.size.height ?? 0) + (selectedMusic() ? 64 : 0)
								}px)`
							: "auto",
				}}
			/>
		</>
	);
};

export default MusicActivity;
