import { createMutation } from "@urql/solid";
import { createSignal } from "solid-js";
import { match } from "ts-pattern";
import { Button } from "~/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "~/components/ui/dialog";
import { useTopAlert } from "~/components/ui/topAlert";
import { graphql } from "~/lib/gql/tada";
import { GoogleOAuth2Scopes, googleOAuth2 } from "~/lib/oauth2";
import { MixpanelEvent, trackEvent } from "../mixpanel";

const FetchYoutubeMutation = graphql.persisted(
	"sha256:c08968833709de08c8460ed00e98d86dc354519f4523c0cacaa807ef455d58ec",
	graphql(`
		mutation FetchYoutube($input: FetchYoutubeInput!) {
			fetchYoutube(input: $input) {
				__typename
				... on FetchYoutubeSingularPayload {
					channel {
						id
						name
						profileImage
					}
				}
			}
		}
	`),
);

export const useAddChannel = () => {
	const [, fetchYoutube] = createMutation(FetchYoutubeMutation);
	const [showAlreadyOwnedDialog, setShowAlreadyOwnedDialog] =
		createSignal(false);
	const topAlert = useTopAlert();

	return [
		async () => {
			const result = await googleOAuth2(GoogleOAuth2Scopes.YouTube);
			// TODO: Manage channel types in a consistent and intuitive way
			const channel = await fetchYoutube({
				input: { accessToken: result.access_token },
			})
				.then((res) =>
					match(res.data?.fetchYoutube)
						.with(
							{ __typename: "FetchYoutubeSingularPayload" },
							({ channel }) => channel,
						)
						.with(
							{ __typename: "FetchYoutubePluralPayload" },
							(): Parameters<typeof topAlert.open>[0] => ({
								variant: "error",
								duration: 10000,
								children: (
									<div>
										<p>Failed to determine channel to fetch</p>
										<p>
											Please contact us through{" "}
											<a href="mailto:support@backpac.app">
												support@backpac.app
											</a>
										</p>
									</div>
								),
							}),
						)
						.with(
							{ __typename: "NotFoundError" },
							(): Parameters<typeof topAlert.open>[0] => ({
								variant: "error",
								children: (
									<div>
										<p>Looks like the Google account you are using</p>
										<p>doesn't have any YouTube channel 🥲</p>
									</div>
								),
							}),
						)
						.with(
							{ __typename: "ChannelAlreadyOwnedError" },
							() => () => setShowAlreadyOwnedDialog(true),
						)
						.otherwise(() => undefined),
				)
				.catch(() => undefined);

			// TODO: Refactor this weirdness
			const payload: Record<string, unknown> = {
				channel_id: undefined,
				is_success: false,
			};

			if (!channel) {
				topAlert.open({
					variant: "error",
					children: (
						<div>
							<p>Failed to fetch YouTube channel</p>
							<p>Please try again</p>
						</div>
					),
				});
			} else if ("children" in channel) {
				topAlert.open(channel);
			} else if (typeof channel === "function") {
				channel();
			} else {
				payload.channel_id = channel.id;
				payload.is_success = true;
			}

			trackEvent(MixpanelEvent.ChannelAdded, payload);
		},
		() => (
			<Dialog
				open={showAlreadyOwnedDialog()}
				onOpenChange={setShowAlreadyOwnedDialog}
			>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Check your account</DialogTitle>
					</DialogHeader>
					<p class="env-mobile:prose-sm">
						The YouTube channel you are trying to add is already linked to
						another BackPac account. Each channel can only connect to one
						account. Please check your account or use a different channel.
					</p>
					<DialogFooter>
						<Button onClick={() => setShowAlreadyOwnedDialog(false)}>
							Okay
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		),
	] as const;
};
