import type { ActivityComponentType } from "@contentstech/stackflow-solid/future";
import { Button as RawButton } from "@kobalte/core/button";
import { RadioGroup } from "@kobalte/core/radio-group";
import { Select } from "@kobalte/core/select";
import { Tabs } from "@kobalte/core/tabs";
import {
	For,
	type JSX,
	Match,
	Show,
	Suspense,
	Switch,
	createEffect,
	createMemo,
	createRenderEffect,
	createSignal,
} from "solid-js";
import { Temporal } from "temporal-polyfill";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import { Divider } from "~/components/ui/divider";
import {
	Drawer,
	DrawerContent,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "~/components/ui/drawer";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "~/components/ui/dropdown";
import { Image } from "~/components/ui/image";
import { Skeleton } from "~/components/ui/skeleton";
import { WebLink } from "~/components/webLink";
import { useAddChannel } from "~/lib/actions/addChannel";
import { HELP_CENTER_ROOT_URL } from "~/lib/constants/urls";
import { environment } from "~/lib/environment";
import { createToeQuery } from "~/lib/gql/createToeQuery";
import { graphql } from "~/lib/gql/tada";
import { latest } from "~/lib/latest";
import { cn } from "~/lib/utils";
import { ChannelAnalyticsContainer } from "./channelAnalytics";
import { ContentAnalyticsContainer } from "./contentAnalytics";
import { DateRangePicker } from "./dateRangePicker";

declare module "@stackflow/config" {
	interface Register {
		DashboardActivity: Record<string, never>;
	}
}

type ChannelInfoProps = {
	image: JSX.Element;
	title: JSX.Element;
};

const AddChannel = () => {
	const [addChannel, addChannelErrorDialog] = useAddChannel();

	return (
		<>
			<RawButton class="flex gap-4 items-center w-fit" onClick={addChannel}>
				<div class="flex items-center justify-center size-70px rounded-full overflow-hidden bg-highlighted">
					<i class="inline-block i-bp-add" />
				</div>
				<div class="prose-md text-primary">Add channel</div>
			</RawButton>
			{addChannelErrorDialog()}
		</>
	);
};

const ChannelInfo = (props: ChannelInfoProps) => (
	<div class="flex gap-16px items-center env-desktop:(gap-12px)">
		<div class="size-70px rounded-full overflow-hidden env-desktop:(size-48px)">
			{props.image}
		</div>
		<div class="prose-md text-primary">{props.title}</div>
	</div>
);

type ChannelListProps = {
	channels: { id: string; name: string; profileImage: string }[];
	activeChannelId: string | undefined;
	onChannelClick: (channelId: string) => void;
};

const ChannelList = (props: ChannelListProps) => {
	return (
		<div class="flex gap-6 items-stretch">
			<For each={props.channels}>
				{(channel) => (
					<RawButton
						class="flex flex-col items-center gap-6px transition-opacity"
						classList={{ "opacity-50": channel.id !== props.activeChannelId }}
						onClick={() => props.onChannelClick(channel.id)}
					>
						<Suspense fallback={<Skeleton circle width="54px" height="54px" />}>
							<div
								class={cn(
									"relative size-54px overflow-hidden border border-2 border-transparent p-1px rounded-full transition-colors",
									channel.id === props.activeChannelId && "border-primary",
								)}
							>
								<Image
									src={channel.profileImage!}
									class="rounded-full"
									alt={`Channel image of ${channel.name}`}
								/>
							</div>
						</Suspense>
						<div
							class={cn(
								"prose-xs",
								channel.id === props.activeChannelId
									? "text-primary"
									: "text-secondary",
							)}
						>
							{channel.name}
						</div>
					</RawButton>
				)}
			</For>
		</div>
	);
};

// TODO: Factor out the metric info to a separate component
const DayRangeSelector = (props: {
	range: typeof DEFAULT_DATE_RANGE;
	value: number | undefined;
	onChange: (value: number | null) => void;
}) => {
	return (
		<Select
			options={[...props.range]}
			defaultValue={props.range[0]}
			disallowEmptySelection
			value={props.value}
			placement="bottom-end"
			onChange={(v) => props.onChange(v)}
			itemComponent={(_props) => (
				<Select.Item
					item={_props.item}
					class="text-4 flex items-center justify-between relative select-none outline-none px-16px py-12px"
				>
					<Select.ItemLabel>{_props.item.rawValue} days</Select.ItemLabel>
					<i
						class={cn(
							"inline-block size-24px",
							_props.item.rawValue === props.value
								? "i-bp-radio-selected"
								: "i-bp-radio text-border-light",
						)}
					/>
				</Select.Item>
			)}
		>
			<Select.Trigger class="flex rounded-full px-4 py-9px prose-sm-b bg-background-off-white text-primary transition-colors outline-none focus:outline-none data-[checked]:(bg-primary text-white)">
				<Select.Value>
					{(state) => `${state.selectedOption()} days`}
				</Select.Value>
				<Select.Icon class="ml-2 inline-flex">
					<i class="inline-block i-bp-chevron-down" />
				</Select.Icon>
			</Select.Trigger>
			<Select.Portal>
				<Select.Content class="bg-white rounded-12px border-1px z-9999 w-180px shadow-[0px_4px_12px_0px_rgba(0,0,0,0.15)]">
					<Select.Listbox class="overflow-y-auto max-h-360px py-8px" />
				</Select.Content>
			</Select.Portal>
		</Select>
	);
};

const DashboardQuery = graphql.persisted(
	"sha256:796bbba2818d6ee4be2358a39a208431d609d33d89ea3098606b4233508ef00a",
	graphql(
		`
			query Dashboard { 
				ownedChannels {
					id
					name
					profileImage
				}
				dataAvailableUntil
			}
		`,
	),
);

const DEFAULT_DATE_RANGE = [7, 14, 30] as const;
enum DashboardTab {
	ANALYTICS = "Analytics",
	CONTENT = "Content",
}

// Helper function to convert days to date range
const getDaysRange = (days: number, until: Temporal.PlainDate) => {
	const end = Temporal.PlainDate.from(until);
	const start = end.subtract({ days: days - 1 });
	return [start, end] as [Temporal.PlainDate, Temporal.PlainDate];
};

const DashboardActivity: ActivityComponentType<"DashboardActivity"> = () => {
	const [activeChannelId, setActiveChannelId] = createSignal<string>();
	const [addChannel, addChannelErrorDialog] = useAddChannel();
	const [isCustomDateRangeOpen, setIsCustomDateRangeOpen] = createSignal(false);
	const [selectedTab, setSelectedTab] = createSignal<DashboardTab>(
		DashboardTab.ANALYTICS,
	);
	const [recentDays, setRecentDays] = createSignal<
		(typeof DEFAULT_DATE_RANGE)[number] | undefined
	>(DEFAULT_DATE_RANGE[0], { equals: false });

	const [selectedDateRange, setSelectedDateRange] = createSignal<
		[Temporal.PlainDate, Temporal.PlainDate]
	>([Temporal.Now.plainDateISO(), Temporal.Now.plainDateISO()]);

	const [query] = createToeQuery({
		query: DashboardQuery,
	});

	const dateAvailableUntil = createMemo(() => {
		const until = latest(() => query.data)?.dataAvailableUntil;
		return until ? Temporal.PlainDate.from(until) : undefined;
	});

	const ownedChannels = createMemo(
		() => latest(() => query.data)?.ownedChannels ?? [],
	);

	// Sync selected date range with recent days
	createEffect(() => {
		if (recentDays() && dateAvailableUntil()) {
			setSelectedDateRange(getDaysRange(recentDays()!, dateAvailableUntil()!));
		}
	});

	createRenderEffect(() => {
		if (
			activeChannelId() == null &&
			(latest(() => query.data?.ownedChannels.length) ?? 0) > 0
		) {
			setActiveChannelId(latest(() => query.data)!.ownedChannels[0]!.id);
		}
	});

	return (
		<div class="flex flex-col">
			<AppBar
				title="Dashboard"
				actions={[
					<Suspense>
						<Show when={environment === "mobile"}>
							<WebLink
								class="px-4 py-6px -my-2 flex items-center gap-6px text-primary prose-xs-b rounded-full border-2 border-border-dark"
								href={HELP_CENTER_ROOT_URL}
							>
								Guide
								<i class="inline-block i-bp-next" />
							</WebLink>
						</Show>
						<Show when={environment === "desktop" && latest(() => query.data)}>
							<Button variant="highlighted" size="sm" onClick={addChannel}>
								Add channel
							</Button>
						</Show>
					</Suspense>,
				]}
			/>
			<div class="flex flex-col gap-10px env-desktop:(mt-16px gap-16px)">
				<div class="env-mobile:px-16px env-desktop:px-32px">
					<Suspense
						fallback={
							<ChannelInfo
								image={<Skeleton circle width="54px" height="54px" />}
								title={<Skeleton width={83} height={22} />}
							/>
						}
					>
						<Show when={query.data}>
							<Switch>
								<Match
									when={
										environment === "desktop" && ownedChannels().length === 0
									}
								>
									<div class="flex items-center prose-md text-primary text-primary gap-8px">
										<i class="inline-block i-bp-info text-primary size-16px" />
										Connect your channel now to get started
									</div>
								</Match>
								<Match
									when={
										environment === "mobile" && ownedChannels().length === 0
									}
								>
									<AddChannel />
								</Match>
								<Match when={ownedChannels().length === 1}>
									<ChannelInfo
										image={
											<Image
												src={ownedChannels()[0]!.profileImage}
												alt={`Channel image of ${ownedChannels()[0]!.name}`}
											/>
										}
										title={ownedChannels()[0]!.name}
									/>
								</Match>
								<Match when={ownedChannels().length > 1}>
									<ChannelList
										channels={ownedChannels()}
										activeChannelId={activeChannelId()}
										onChannelClick={setActiveChannelId}
									/>
								</Match>
							</Switch>
						</Show>
					</Suspense>
				</div>
				<div class="env-mobile:mx-16px env-desktop:mx-32px flex flex-col env-desktop:(max-w-1080px)">
					<div class="sticky top-0 z-10 bg-background mb-16px justify-between flex flex-col gap-4 env-desktop:(flex-row items-center border-b border-border-light)">
						<Tabs
							value={selectedTab()}
							onChange={(value) => setSelectedTab(value as DashboardTab)}
							class="env-desktop:(ml-[-16px]) env-mobile:(border-b border-border-light)"
						>
							<Tabs.List class="flex border-none relative">
								<div class="flex env-mobile:(justify-center flex-1 gap-80px)">
									{Object.values(DashboardTab).map((tab) => (
										<Tabs.Trigger
											value={tab}
											class="mx-16px py-12px prose-md-b text-tertiary data-[selected]:text-primary hover:text-secondary focus:outline-none"
										>
											{tab}
										</Tabs.Trigger>
									))}
								</div>
								<Tabs.Indicator class="absolute bottom-0 bg-black transition-all duration-300 bg-primary h-2px" />
							</Tabs.List>
						</Tabs>

						<Switch>
							<Match when={environment === "desktop"}>
								<div class="flex gap-10px">
									<DayRangeSelector
										range={DEFAULT_DATE_RANGE}
										value={recentDays()}
										onChange={setRecentDays}
									/>
									<DropdownMenu
										open={isCustomDateRangeOpen()}
										onOpenChange={setIsCustomDateRangeOpen}
									>
										<DropdownMenuTrigger
											class={cn(
												"flex rounded-full h-min p-10px bg-background-off-white transition-colors",
												!recentDays() && "bg-primary",
											)}
										>
											<i
												class={cn(
													"inline-block i-bp-calendar size-16px text-primary",
													!recentDays() && "text-white",
												)}
											/>
										</DropdownMenuTrigger>
										<DropdownMenuContent class="z-20 bg-white rounded-t-16px shadow-popover! border-none">
											<div class="flex flex-col w-390px p-16px pt-30px gap-8px">
												<h3 class="prose-md-b self-center pb-8px">Custom</h3>
												<Divider size="full" />
												<DateRangePicker
													maxDate={dateAvailableUntil()}
													selectedDateRange={selectedDateRange()}
													onDateRangeSelected={(v) => {
														setSelectedDateRange(v);
														setRecentDays(undefined);
														setIsCustomDateRangeOpen(false);
													}}
												/>
											</div>
										</DropdownMenuContent>
									</DropdownMenu>
								</div>
							</Match>
							<Match when={environment === "mobile"}>
								<RadioGroup
									value={recentDays()?.toString()}
									onChange={(v) =>
										setRecentDays(
											Number(v) as (typeof DEFAULT_DATE_RANGE)[number],
										)
									}
								>
									<div class="flex gap-6px">
										<For each={DEFAULT_DATE_RANGE}>
											{(days) => (
												<RadioGroup.Item value={days.toString()}>
													<RadioGroup.ItemInput />
													<RadioGroup.ItemControl class="rounded-full px-4 py-9px prose-sm-b bg-background-off-white text-primary transition-colors data-[checked]:(bg-primary text-white)">
														{days} days
													</RadioGroup.ItemControl>
												</RadioGroup.Item>
											)}
										</For>
										<Drawer
											open={isCustomDateRangeOpen()}
											onOpenChange={setIsCustomDateRangeOpen}
										>
											<DrawerTrigger
												class={cn(
													"flex rounded-full h-min p-10px bg-background-off-white transition-colors",
													!recentDays() && "bg-primary",
												)}
											>
												<i
													class={cn(
														"inline-block i-bp-calendar size-16px text-primary",
														!recentDays() && "text-white",
													)}
												/>
											</DrawerTrigger>
											<DrawerContent class="p-16px pt-0 gap-0">
												<DrawerHeader class="mb-10px">
													<DrawerTitle>Custom</DrawerTitle>
												</DrawerHeader>
												<DateRangePicker
													maxDate={dateAvailableUntil()}
													selectedDateRange={selectedDateRange()}
													onDateRangeSelected={(v) => {
														setSelectedDateRange(v);
														setRecentDays(undefined);
														setIsCustomDateRangeOpen(false);
													}}
												/>
											</DrawerContent>
										</Drawer>
									</div>
								</RadioGroup>
							</Match>
						</Switch>
					</div>
					<Switch>
						<Match when={selectedTab() === DashboardTab.ANALYTICS}>
							<ChannelAnalyticsContainer
								selectedChannelId={activeChannelId()}
								selectedDateRange={selectedDateRange()}
							/>
						</Match>
						<Match when={selectedTab() === DashboardTab.CONTENT}>
							<ContentAnalyticsContainer
								selectedChannelId={activeChannelId()}
								selectedDateRange={selectedDateRange()}
							/>
						</Match>
					</Switch>
				</div>
			</div>
			{addChannelErrorDialog()}
		</div>
	);
};

export default DashboardActivity;
