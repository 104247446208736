import {
	type JSX,
	Show,
	createResource,
	mergeProps,
	splitProps,
} from "solid-js";

type ImageProps = JSX.ImgHTMLAttributes<HTMLImageElement> & {
	alt: string;
	suspend?: boolean;
};

// Currently, users always wrap this component in a Suspense component and it feels cumbersome
// TODO: Handle suspend gracefully
export const Image = (_props: ImageProps) => {
	const props = mergeProps({ suspend: true }, _props);
	const [locals, others] = splitProps(props, ["src", "suspend"]);

	const [loaded] = createResource(
		() => ({
			suspend: locals.suspend,
			src: locals.src,
		}),
		({ suspend, src }) => {
			if (!suspend || src == null) return true;
			const image = new window.Image();
			image.src = src;
			if (image.complete) return true;
			return new Promise<boolean>((resolve) => {
				image.onload = () => resolve(true);
			});
		},
	);

	return (
		<Show when={loaded()}>
			{/* biome-ignore lint/a11y/useAltText: always enforced on types */}
			<img src={locals.src} {...others} />
		</Show>
	);
};
