import { Clipboard } from "@capacitor/clipboard";
import { Button as RawButton } from "@kobalte/core/button";
import { Tooltip } from "@kobalte/core/tooltip";
import { createMutation } from "@urql/solid";
import { type JSX, Show, Suspense, createMemo, splitProps } from "solid-js";
import { Dynamic } from "solid-js/web";
import { Temporal } from "temporal-polyfill";
import { P, match } from "ts-pattern";
import {
	FavoriteMusicMutation,
	UnfavoriteMusicMutation,
} from "~/activities/root/music";
import { generateYoutubeShortsDeeplink } from "~/lib/constants/urls";
import { environment } from "~/lib/environment";
import { type FragmentOf, graphql, readFragment } from "~/lib/gql/tada";
import { MixpanelEvent, TrackingWrapper, trackEvent } from "~/lib/mixpanel";
import { cn } from "~/lib/utils";
import { Moods } from "~/types/music";
import { Passthrough } from "./passthrough";
import { Button } from "./ui/button";
import {
	Drawer,
	DrawerContent,
	DrawerDescription,
	DrawerHeader,
	DrawerTitle,
} from "./ui/drawer";
import { Image } from "./ui/image";
import { Skeleton } from "./ui/skeleton";
import { showToast } from "./ui/toast";
import { useTopAlert } from "./ui/topAlert";
import {
	YoutubeActionButton,
	YoutubeActionButtonFragment,
} from "./youtubeActionButton";

type MusicListItemProps = Omit<
	MusicListItemLayoutProps,
	"albumArt" | "metadata" | "waveform"
> & {
	$music?: FragmentOf<typeof MusicListItemFragment>;
	isPlaying?: boolean;
	onPlayingChange: (playing: boolean) => void;
	onActionsClick?: () => void;
};

const durationFormat = new Intl.DurationFormat(undefined, {
	minutes: "numeric",
	seconds: "numeric",
});

export const MusicListItemFragment = graphql(
	`
	fragment MusicListItem on Music {
		id
		title {
			en
		}
		coverImage
		moods
		isFavorite
		isrc
		youtubeVideoId
		duration
		...YoutubeActionButton
	}
`,
	[YoutubeActionButtonFragment],
);

export const MusicListItem = (props: MusicListItemProps) => {
	const [locals, others] = splitProps(props, [
		"$music",
		"isSelected",
		"isPlaying",
		"onActionsClick",
		"onPlayingChange",
	]);
	const music = createMemo(() =>
		readFragment(MusicListItemFragment, locals.$music),
	);

	return (
		<MusicListItemLayout
			albumArt={
				<Suspense fallback={<Skeleton />}>
					<Show when={music()} fallback={<Skeleton />}>
						{(music) => (
							<RawButton
								class="relative size-full"
								onClick={(e: MouseEvent | TouchEvent) => {
									if (locals.isSelected) {
										e.stopPropagation();
										locals.onPlayingChange(!locals.isPlaying);
									}
								}}
							>
								<Image
									src={new URL(
										music().coverImage,
										import.meta.env.VITE_API_BASE,
									).toString()}
									alt={`Album art of ${music().title.en}`}
									class="size-full object-cover"
								/>
								<div
									class={cn(
										"absolute inset-0 z-1 transition-colors",
										locals.isSelected ? "bg-black/60" : "bg-transparent",
									)}
								>
									<Show when={locals.isSelected}>
										<Show when={locals.isPlaying}>
											<div class="absolute inset-0 m-auto size-10px flex justify-between env-desktop:hidden">
												<div class="w-2px h-full bg-white transform-origin-b scale-y-10 animate-sound-wave animate-delay-100" />
												<div class="w-2px h-full bg-white transform-origin-b scale-y-10 animate-sound-wave animate-delay-500" />
												<div class="w-2px h-full bg-white transform-origin-b scale-y-10 animate-sound-wave animate-delay-300" />
											</div>
										</Show>
										<div
											class={cn(
												"absolute inset-0 m-auto text-white",
												locals.isPlaying ? "i-bp-pause-fill" : "i-bp-play-fill",
												"env-mobile:hidden",
											)}
										/>
									</Show>
								</div>
							</RawButton>
						)}
					</Show>
				</Suspense>
			}
			metadata={
				<Suspense fallback={<Skeleton width={130} height={20} />}>
					<Show when={music()} fallback={<Skeleton width={130} height={20} />}>
						{(music) => (
							<>
								<Dynamic
									component={environment === "mobile" ? "div" : Passthrough}
								>
									<div class="flex items-center gap-6px min-w-0 env-mobile:col-span-5 env-mobile:mb-4px">
										<Show when={environment === "mobile" && music().isFavorite}>
											<i class="inline-block size-12px i-bp-heart-fill text-functional-red" />
										</Show>
										<span class="prose-sm text-primary overflow-hidden text-ellipsis whitespace-nowrap">
											{music().title.en}
										</span>
									</div>
									<Dynamic
										component={environment === "mobile" ? "div" : Passthrough}
										class="flex gap-6px items-center"
									>
										<div class="overflow-hidden text-ellipsis whitespace-nowrap min-w-0 prose-xs text-tertiary">
											{music()
												.moods.map((m) => Moods[m].title)
												.join(", ")}
										</div>
										<div class="size-3px rounded-full bg-border-dark env-desktop:hidden" />
										<div class="env-mobile:hidden" />
										<span class="prose-xs text-tertiary">
											{durationFormat.format(
												Temporal.Duration.from({
													seconds: Math.round(music().duration),
												}).round({ largestUnit: "minute" }),
											)}
										</span>
									</Dynamic>
								</Dynamic>
							</>
						)}
					</Show>
				</Suspense>
			}
			actions={
				<div
					class={cn(
						"justify-center items-center",
						"env-desktop:(grid gap-20px gap-4 grid-cols-[repeat(2,56px)])",
						"env-mobile:(flex gap-20px justify-center items-center)",
					)}
				>
					<Show when={environment === "desktop" && music()}>
						{(music) => (
							<>
								<FavoriteButton
									musicId={music().id}
									isFavorite={music().isFavorite}
								/>
								<YoutubeActionButton $music={music()} environment="desktop" />
							</>
						)}
					</Show>
					<Show when={environment === "mobile"}>
						<YoutubeActionButton
							$music={music()}
							environment="mobile"
							onActionsClick={locals.onActionsClick}
						/>
					</Show>
				</div>
			}
			waveform={
				<Suspense
					fallback={
						<div class="h-full flex items-center">
							<Skeleton height={10} />
						</div>
					}
				>
					<Show
						when={music()}
						fallback={
							<div class="h-full flex items-center">
								<Skeleton height={10} />
							</div>
						}
					>
						<div />
					</Show>
				</Suspense>
			}
			isSelected={locals.isSelected}
			{...others}
		/>
	);
};

type MusicListItemLayoutProps = JSX.HTMLAttributes<HTMLLIElement> & {
	albumArt: JSX.Element;
	metadata: JSX.Element;
	actions?: JSX.Element;
	waveform: JSX.Element;
	isSelected?: boolean;
	onSelect?: () => void;
};

const MusicListItemLayout = (props: MusicListItemLayoutProps) => {
	const [locals, others] = splitProps(props, [
		"class",
		"albumArt",
		"metadata",
		"actions",
		"waveform",
		"isSelected",
		"onSelect",
	]);

	return (
		<li
			class={cn(
				"flex flex-col gap-1 px-4 relative env-desktop:(px-8 py-10px border-y border-border-light hover:bg-white)",
				locals.isSelected &&
					"env-desktop:after:(absolute content-empty left-0 top-0 bottom-0 w-4px bg-primary)",
				locals.class,
			)}
			{...others}
		>
			<div class="flex items-center justify-between gap-20px env-desktop:gap-4">
				<RawButton
					class={cn(
						"grid items-center min-w-0 flex-grow-1",
						"env-mobile:(grid-cols-[repeat(4,minmax(0,max-content))_1fr] grid-rows-[1fr_max-content_max-content_1fr] gap-row-2px)",
						"env-desktop:(grid-cols-[56px_3fr_3fr_4fr_1fr] grid-rows-1 gap-col-4)",
					)}
					onClick={locals.onSelect}
				>
					<div
						class={cn(
							"rounded-4px overflow-hidden",
							"env-mobile:(size-12 mr-3 row-span-4)",
							"env-desktop:(size-56px rounded-6px)",
						)}
					>
						{locals.albumArt}
					</div>
					<div class="grid col-start-2 col-end-6 items-center min-w-0 grid-cols-subgrid grid-rows-subgrid text-left env-mobile:(row-start-2 row-end-3 gap-col-1)">
						{locals.metadata}
					</div>
				</RawButton>
				{locals.actions}
			</div>
			{/* <div class="h-30px">{locals.waveform}</div> */}
		</li>
	);
};

export const MusicActionsDrawerFragment = graphql(`
	fragment MusicActionsDrawer on Music {
		id
		title {
			en
		}
		moods
		isrc
		youtubeVideoId
		coverImage
		isFavorite
	}
`);

type FavoriteButtonProps = {
	musicId: string;
	isFavorite: boolean;
};

export const FavoriteButton = (props: FavoriteButtonProps) => {
	const [, favoriteMusic] = createMutation(FavoriteMusicMutation);
	const [, unfavoriteMusic] = createMutation(UnfavoriteMusicMutation);

	return (
		<Tooltip placement="top" gutter={4} openDelay={0} closeDelay={0}>
			<Tooltip.Trigger
				onClick={(e) => {
					e.stopPropagation();
					trackEvent(
						props.isFavorite
							? MixpanelEvent.UnfavoriteMusic
							: MixpanelEvent.FavoriteMusic,
						{
							music_id: props.musicId,
						},
					);
					const mutation = props.isFavorite ? unfavoriteMusic : favoriteMusic;
					mutation({ id: props.musicId });
				}}
				class="mx-auto size-40px rounded-6px flex justify-center items-center hover:bg-background-dark"
			>
				<i
					class={cn(
						"inline-block size-24px",
						props.isFavorite
							? "i-bp-heart-fill text-functional-red"
							: "i-bp-heart",
					)}
				/>
			</Tooltip.Trigger>
			<Tooltip.Portal>
				<Tooltip.Content class="z-20 bg-black/60 rounded-4px px-6px py-2px text-white prose-2xs !animate-duration-100 animate-fade-out data-[expanded]:animate-fade-in">
					<p>{props.isFavorite ? "Remove favorite" : "Favorite"}</p>
				</Tooltip.Content>
			</Tooltip.Portal>
		</Tooltip>
	);
};

type MusicActionsDrawerProps = {
	$music: FragmentOf<typeof MusicActionsDrawerFragment>;
	isOpen: boolean;
	onOpenchange?: (open: boolean) => void;
};

export const MusicActionsDrawer = (props: MusicActionsDrawerProps) => {
	const topAlert = useTopAlert();
	const music = createMemo(() =>
		readFragment(MusicActionsDrawerFragment, props.$music),
	);
	const [, favoriteMusic] = createMutation(FavoriteMusicMutation);
	const [, unfavoriteMusic] = createMutation(UnfavoriteMusicMutation);

	return (
		<Drawer open={props.isOpen} onOpenChange={props.onOpenchange}>
			<DrawerContent>
				<DrawerHeader class="flex-row items-center text-left gap-12px">
					<Image
						src={new URL(
							music().coverImage,
							import.meta.env.VITE_API_BASE,
						).toString()}
						alt={`Album art of ${music().title.en}`}
						class="rounded-4px size-48px object-cover"
					/>
					<div class="flex flex-col gap-1">
						<DrawerTitle class="prose-sm">{music().title.en}</DrawerTitle>
						<DrawerDescription class="prose-xs">
							{music().isrc}
						</DrawerDescription>
					</div>
				</DrawerHeader>
				<div class="p-16px mb-14px flex flex-col gap-20px">
					<div class="grid grid-cols-2 gap-12px">
						<div class="flex flex-col items-center gap-8px text-primary prose-xs">
							<TrackingWrapper
								trackingEvent={
									music().isFavorite
										? MixpanelEvent.UnfavoriteMusic
										: MixpanelEvent.FavoriteMusic
								}
								trackingData={{
									music_id: music().id,
								}}
							>
								<RawButton
									class="flex flex-col w-full justify-center items-center gap-6px bg-background-dark rounded-10px h-64px"
									onClick={async () => {
										const isFavorite = music().isFavorite;
										const mutation = isFavorite
											? unfavoriteMusic
											: favoriteMusic;
										props.onOpenchange?.(false);
										const result = await mutation({ id: music().id });
										match(result.data?.mutate)
											.with({ music: { id: P.string } }, () => {
												showToast({
													message: `Music ${
														isFavorite ? "removed from" : "added to"
													} [Favorites]`,
												});
											})
											.otherwise(() => {
												topAlert.open({
													variant: "error",
													children: (
														<div>
															<p>
																Failed to {isFavorite ? "remove" : "add"} music{" "}
																{isFavorite ? "from" : "to"} [Favorites]
															</p>
															<p>Please try again</p>
														</div>
													),
												});
											});
									}}
								>
									<i
										class={cn(
											"inline-block size-24px",
											music().isFavorite
												? "i-bp-heart-fill text-functional-red"
												: "i-bp-heart",
										)}
									/>
								</RawButton>
							</TrackingWrapper>
							Favorite
						</div>
						<div class="flex flex-col items-center gap-8px text-primary prose-xs">
							<RawButton
								class="flex flex-col w-full justify-center items-center gap-6px bg-background-dark rounded-10px h-64px"
								onClick={async () => {
									await Clipboard.write({
										string: music().isrc,
									});
									showToast({
										message: `Code copied [${music().isrc}]`,
									});
									props.onOpenchange?.(false);
								}}
							>
								<i class="inline-block size-24px i-bp-copy" />
							</RawButton>
							Copy Code
						</div>
					</div>
					<a href={generateYoutubeShortsDeeplink(music().youtubeVideoId)}>
						<Button variant="highlighted" class="w-full">
							<div class="flex items-center gap-8px">
								<i class="inline-block i-bp-channel" />
								Use this sound
								<i class="inline-block i-bp-external" />
							</div>
						</Button>
					</a>
				</div>
			</DrawerContent>
		</Drawer>
	);
};
