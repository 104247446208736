import { AppScreen } from "@contentstech/stackflow-plugin-basic-ui/solid";
import { useParent } from "@contentstech/stackflow-plugin-omniflow";
import {
	type ActivityComponentType,
	useFlow,
} from "@contentstech/stackflow-solid/future";
import { Select } from "@kobalte/core/select";
import {
	createForm,
	getValues,
	setValue,
	setValues,
	valiForm,
} from "@modular-forms/solid";
import { createMutation } from "@urql/solid";
import { Show, createEffect } from "solid-js";
import { Dynamic } from "solid-js/web";
import { Temporal } from "temporal-polyfill";
import * as v from "valibot";
import { Passthrough } from "~/components/passthrough";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import { FormTextField } from "~/components/ui/textField";
import { useTopAlert } from "~/components/ui/topAlert";
import { WebLink } from "~/components/webLink";
import { COUNTRIES, COUNTRY_MAP } from "~/lib/constants/country";
import { PRIVACY_POLICY_URL } from "~/lib/constants/urls";
import { environment } from "~/lib/environment";
import { createToeQuery } from "~/lib/gql/createToeQuery";
import { graphql, readFragment } from "~/lib/gql/tada";
import { MixpanelEvent, trackEvent } from "~/lib/mixpanel";
import { cn } from "~/lib/utils";

declare module "@stackflow/config" {
	interface Register {
		PayoutDetailsActivity: Record<string, never>;
	}
}

export const PayoutDetailsFragment = graphql(`
	fragment PayoutDetails on PayoutDetails {
		__typename
		fullLegalName
		dateOfBirth
		phoneCountryCode
		phoneNationalNumber
		country
		city
		stateOrProvince
		streetAddressFirst
		streetAddressSecond
		postalCode
		recipientType
		accountHolder
		bankName
		billingCountry
		swiftOrBicCode
		accountNumberOrIban
	}
`);

const PayoutDetailsQuery = graphql.persisted(
	"sha256:02e9d5bd699a70315b999effb19a7da6f0cedb9658c81b989ce933c6b03beb2f",
	graphql(
		`
			query PayoutDetails {
				payoutDetails {
					...PayoutDetails
				}
			}
		`,
		[PayoutDetailsFragment],
	),
);

export const UpdatePayoutDetailsMutation = graphql.persisted(
	"sha256:3c32333afd68e1b8633931668b9bb8a9bd663eb8c382feb62fd0d641a0f25d36",
	graphql(
		`
			mutation UpdatePayoutDetails($input: UpdatePayoutDetailsInput!) {
				updatePayoutDetails(input: $input) {
					__typename
					... on UpdatePayoutDetailsPayload {
						payoutDetails {
							...PayoutDetails
						}
					}
				}
			}
		`,
		[PayoutDetailsFragment],
	),
);

type RecipientType = ReturnType<typeof graphql.scalar<"RecipientType">>;
const RecipientType = {
	PERSONAL: "PERSONAL",
	COMPANY: "COMPANY",
} as const satisfies Record<RecipientType, RecipientType>;

const PayoutDetailsInput = v.object({
	fullLegalName: v.pipe(
		v.string(),
		v.string(),
		v.nonEmpty("Name must not be empty."),
	),
	dateOfBirth: v.pipe(
		v.string(),
		v.regex(
			/^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
			"Please enter a valid date in DD/MM/YYYY format",
		),
		v.rawTransform(({ dataset, addIssue, NEVER }) => {
			const [day, month, year] = dataset.value.split("/").map(Number) as [
				number,
				number,
				number,
			];

			let inputDate: Temporal.PlainDate;
			try {
				inputDate = Temporal.PlainDate.from({ year, month, day });
			} catch {
				addIssue({ message: "Please enter a valid date" });
				return NEVER;
			}

			const isInputDatePast =
				Temporal.PlainDate.compare(inputDate, Temporal.Now.plainDateISO()) < 0;
			if (!isInputDatePast) {
				addIssue({ message: "The date of birth must be in the past" });
				return NEVER;
			}

			return inputDate.toString();
		}),
	),
	phoneCountryCode: v.pipe(
		v.string(),
		v.nonEmpty("Phone country code must not be empty."),
	),
	phoneNationalNumber: v.pipe(
		v.string(),
		v.nonEmpty("Phone number must not be empty."),
	),
	country: v.pipe(v.string(), v.nonEmpty("Country must not be empty.")),
	city: v.pipe(v.string(), v.nonEmpty("City must not be empty.")),
	stateOrProvince: v.pipe(v.string(), v.nonEmpty("State must not be empty.")),
	streetAddressFirst: v.pipe(
		v.string(),
		v.nonEmpty("Street address must not be empty."),
	),
	streetAddressSecond: v.string(),
	postalCode: v.pipe(
		v.string(),
		v.regex(
			/^[a-zA-Z0-9\s-]*$/,
			"Postal code can only contain letters, numbers, spaces, and hyphens",
		),
	),
	recipientType: v.pipe(
		v.enum(RecipientType),
		v.nonEmpty("Recipient type must not be empty."),
	),
	accountHolder: v.pipe(
		v.string(),
		v.nonEmpty("Account holder must not be empty."),
	),
	bankName: v.pipe(v.string(), v.nonEmpty("Bank name must not be empty.")),
	billingCountry: v.pipe(
		v.string(),
		v.nonEmpty("Billing country code must not be empty."),
	),
	swiftOrBicCode: v.pipe(
		v.string(),
		v.nonEmpty("SWIFT code must not be empty."),
	),
	accountNumberOrIban: v.pipe(
		v.string(),
		v.nonEmpty("Bank account number must not be empty."),
	),
});

const PayoutDetailsValidator = v.pipe(
	v.intersect([
		PayoutDetailsInput,
		v.object({
			confirmAccountNumberOrIban: v.pipe(
				v.string(),
				v.nonEmpty("Confirm bank account number must not be empty."),
			),
		}),
	]),
	v.forward(
		v.partialCheck(
			[["accountNumberOrIban"], ["confirmAccountNumberOrIban"]],
			(input) => input.accountNumberOrIban === input.confirmAccountNumberOrIban,
			"Bank account numbers do not match.",
		),
		["confirmAccountNumberOrIban"],
	),
);

type PayoutDetailsValidator = v.InferOutput<typeof PayoutDetailsValidator>;

const PayoutDetailsActivity: ActivityComponentType<
	"PayoutDetailsActivity"
> = () => {
	const actions = useFlow();
	const topAlert = useTopAlert();
	const parent = useParent();
	const [query] = createToeQuery({
		query: PayoutDetailsQuery,
	});

	const payoutDetails = () =>
		readFragment(PayoutDetailsFragment, query.data?.payoutDetails);

	const [, updatePayoutDetails] = createMutation(UpdatePayoutDetailsMutation);

	const [form, { Form, Field }] = createForm<PayoutDetailsValidator>({
		validate: valiForm(
			v.config(PayoutDetailsValidator, { abortPipeEarly: false }),
		),
		validateOn: "blur",
		initialValues: {
			country: "US",
			phoneCountryCode: "US",
			billingCountry: "US",
			recipientType: RecipientType.PERSONAL,
		},
	});

	createEffect(() => {
		const details = payoutDetails();
		if (details == null) return;

		const dateOfBirth = Temporal.PlainDate.from(details.dateOfBirth);
		const yyyy = dateOfBirth.year.toString().padStart(4, "0");
		const mm = dateOfBirth.month.toString().padStart(2, "0");
		const dd = dateOfBirth.day.toString().padStart(2, "0");
		setValues(form, {
			...details,
			dateOfBirth: `${dd}/${mm}/${yyyy}`,
			confirmAccountNumberOrIban: details.accountNumberOrIban,
		});
	});

	return (
		<Dynamic component={parent ? Passthrough : AppScreen}>
			<div class="flex flex-col env-mobile:(pb-[calc(var(--safe-area-inset-bottom)+10px)]) env-desktop:(max-w-480px w-full pb-24px)">
				<Show when={environment === "mobile"}>
					<AppBar title="Payout details" />
				</Show>
				<div class="flex-1 flex flex-col gap-32px env-mobile:(px-16px)">
					<p class="prose-sm text-secondary leading-[1.4]">
						Enter your bank account details needed for payouts. Your data is
						protected in line with our
						<span class="underline ml-4px">
							<WebLink href={PRIVACY_POLICY_URL}>Privacy Policies.</WebLink>
						</span>
					</p>
					<Form
						onSubmit={async (data) => {
							const ret = await updatePayoutDetails({
								input: v.parse(PayoutDetailsInput, data),
							});

							if (
								ret.data?.updatePayoutDetails.__typename !==
								"UpdatePayoutDetailsPayload"
							) {
								topAlert.open({
									variant: "error",
									children: (
										<div>
											<p>Failed to update Payment details</p>
										</div>
									),
									duration: 2000,
								});
								return;
							}
							trackEvent(MixpanelEvent.UpdatePayoutDetails, {
								// Note. This seems to be sensitive data so we don't track the result.
							});
							topAlert.open({
								variant: "success",
								children: (
									<div>
										<p>Payment details was saved successfully!</p>
									</div>
								),
								duration: 2000,
							});
							actions.pop();
						}}
						class="flex-1 flex flex-col gap-16px text-left env-desktop:(gap-16px)"
					>
						<div>
							<h3 class="prose-unbounded-lg text-17px text-primary">
								Personal details
							</h3>
							<p class="prose-xs text-secondary leading-[1.4] mt-6px">
								Your personal details are required to verify your identity, and
								your address must match the country of your bank account.
							</p>
						</div>
						<Field name="fullLegalName">
							{(field, props) => (
								<FormTextField
									{...props}
									error={field.error}
									label="Full legal name"
									type="text"
									placeholder="Full name"
									autocomplete="name"
									value={field.value}
								/>
							)}
						</Field>
						<Field name="dateOfBirth">
							{(field, props) => (
								<FormTextField
									{...props}
									wrapperClass="w-157px"
									error={field.error}
									label="Date of birth"
									type="text"
									inputMode="numeric"
									placeholder="DD/MM/YYYY"
									value={field.value}
									onInput={(e) => {
										const input = e.currentTarget.value.replace(/\D/g, "");
										e.currentTarget.value = input;
										let formatted = "";

										if (input.length > 0) formatted += input.substring(0, 2);
										if (input.length > 2)
											formatted += `/${input.substring(2, 4)}`;
										if (input.length > 4)
											formatted += `/${input.substring(4, 8)}`;

										setValue(form, "dateOfBirth", formatted);
									}}
									maxLength={10}
								/>
							)}
						</Field>
						<div>
							<p class="prose-xs text-primary mb-10px">Phone number</p>
							<div class="flex flex-row gap-10px">
								<Field name="phoneCountryCode">
									{(field, props) => (
										<CallingCodeSelect
											class="w-120px"
											selectedCode={field.value!}
											onChange={(code) =>
												setValue(form, "phoneCountryCode", code)
											}
										/>
									)}
								</Field>
								<Field name="phoneNationalNumber">
									{(field, props) => (
										<FormTextField
											{...props}
											error={field.error}
											wrapperClass="flex-1"
											type="tel"
											inputMode="numeric"
											autocomplete="tel"
											placeholder="Phone number"
											value={field.value}
										/>
									)}
								</Field>
							</div>
						</div>
						<div>
							<p class="prose-xs text-primary mb-10px">Country</p>
							<Field name="country">
								{(field, props) => (
									<CountrySelect
										class="w-full"
										selectedCode={field.value!}
										onChange={(code) => setValue(form, "country", code)}
									/>
								)}
							</Field>
						</div>

						<div class="flex flex-row gap-10px">
							<Field name="city">
								{(field, props) => (
									<FormTextField
										{...props}
										label="City"
										error={field.error}
										type="text"
										placeholder="City"
										value={field.value}
										autocomplete="address-level2"
									/>
								)}
							</Field>
							<Field name="stateOrProvince">
								{(field, props) => (
									<FormTextField
										{...props}
										label="State/Province"
										error={field.error}
										type="text"
										placeholder="State/Province"
										value={field.value}
										autocomplete="address-level1"
									/>
								)}
							</Field>
						</div>
						<div class="flex flex-col gap-10px">
							<Field name="streetAddressFirst">
								{(field, props) => (
									<FormTextField
										{...props}
										label="Street address"
										error={field.error}
										type="text"
										placeholder="Street address"
										value={field.value}
										autocomplete="street-address"
									/>
								)}
							</Field>
							<Field name="streetAddressSecond">
								{(field, props) => (
									<FormTextField
										{...props}
										error={field.error}
										type="text"
										placeholder="Apartment, unit, or other"
										autocomplete="address-line2"
										value={field.value}
									/>
								)}
							</Field>
						</div>
						<Field name="postalCode">
							{(field, props) => (
								<FormTextField
									{...props}
									error={field.error}
									label="Postal code"
									wrapperClass="w-1/2"
									type="text"
									placeholder="Postal code"
									autocomplete="postal-code"
									value={field.value}
								/>
							)}
						</Field>
						<div class="mt-16px">
							<h3 class="prose-unbounded-lg text-17px text-primary">
								Bank account
							</h3>
							<p class="prose-xs text-secondary leading-[1.4] mt-6px">
								Enter all bank account details carefully to ensure accurate
								payouts.
							</p>
						</div>
						<div>
							<p class="prose-xs text-primary mb-10px">Recipient type</p>
							<Field name="recipientType">
								{(field, props) => (
									<RecipientTypeSelect
										class="w-full"
										type={field.value!}
										onChange={(type) => setValue(form, "recipientType", type)}
									/>
								)}
							</Field>
						</div>
						<Field name="accountHolder">
							{(field, props) => (
								<FormTextField
									{...props}
									error={field.error}
									label="Account holder"
									type="text"
									placeholder="Full name"
									autocomplete="name"
									value={field.value}
								/>
							)}
						</Field>
						<Field name="bankName">
							{(field, props) => (
								<FormTextField
									{...props}
									error={field.error}
									label="Bank name"
									type="text"
									placeholder="Bank name"
									value={field.value}
								/>
							)}
						</Field>
						<div>
							<p class="prose-xs text-primary mb-10px">Billing country</p>
							<Field name="billingCountry">
								{(field, props) => (
									<CountrySelect
										class="w-full"
										selectedCode={field.value!}
										onChange={(code) => setValue(form, "billingCountry", code)}
									/>
								)}
							</Field>
						</div>
						<Field name="swiftOrBicCode">
							{(field, props) => (
								<FormTextField
									{...props}
									error={field.error}
									label="SWIFT/BIC code"
									type="text"
									placeholder="SWIFT/BIC code"
									value={field.value}
								/>
							)}
						</Field>
						<div class="flex flex-col gap-10px">
							<Field name="accountNumberOrIban">
								{(field, props) => (
									<FormTextField
										{...props}
										label="Account number/IBAN"
										error={field.error}
										inputMode="numeric"
										type="text"
										placeholder="Account number"
										value={field.value}
									/>
								)}
							</Field>
							<Field name="confirmAccountNumberOrIban">
								{(field, props) => (
									<FormTextField
										{...props}
										error={field.error}
										type="text"
										inputMode="numeric"
										placeholder="Confirm account number"
										value={field.value}
									/>
								)}
							</Field>
						</div>
						<Button
							type="submit"
							variant="highlighted"
							class="mt-16px"
							disabled={
								!v.safeParse(PayoutDetailsValidator, getValues(form)).success ||
								form.submitting
							}
						>
							Save
						</Button>
					</Form>
				</div>
			</div>
		</Dynamic>
	);
};

type CountrySelectProps = {
	class?: string;
	selectedCode: string;
	onChange: (code: string) => void;
};

// TODO: Factor out to a shared component
const CountrySelect = (props: CountrySelectProps) => {
	const countryCodes = COUNTRIES.toSorted((a, b) =>
		a.name.localeCompare(b.name),
	).map((c) => c.code);

	return (
		<Select
			class={props.class}
			options={countryCodes}
			disallowEmptySelection
			value={props.selectedCode}
			onChange={(v) => props.onChange(v!)}
			itemComponent={(_props) => (
				<Select.Item
					item={_props.item}
					class={cn(
						"text-4 flex items-center justify-between relative select-none outline-none px-16px py-12px",
						_props.item.rawValue === props.selectedCode && "bg-background-dark",
					)}
				>
					<Select.ItemLabel>
						{COUNTRY_MAP.get(_props.item.rawValue)?.emoji ?? ""}
						{"  "}
						{COUNTRY_MAP.get(_props.item.rawValue)?.name}
					</Select.ItemLabel>
				</Select.Item>
			)}
		>
			<Select.Trigger class="flex w-full items-center justify-between rounded-full px-20px h-46px prose-sm bg-white text-primary outline-none shadow-regular">
				<Select.Value>
					{(state) => (
						<>
							{COUNTRY_MAP.get(state.selectedOption() as string)?.emoji ?? ""}
							{"  "}
							{COUNTRY_MAP.get(state.selectedOption() as string)?.name}
						</>
					)}
				</Select.Value>
				<Select.Icon class="ml-2 inline-flex">
					<i class="inline-block i-bp-chevron-down" />
				</Select.Icon>
			</Select.Trigger>
			<Select.Portal>
				<Select.Content class="bg-white rounded-12px border-1px z-9999 shadow-[0px_4px_12px_0px_rgba(0,0,0,0.15)]">
					<Select.Listbox class="overflow-y-auto max-h-360px py-8px" />
				</Select.Content>
			</Select.Portal>
		</Select>
	);
};

type CallingCodeSelectProps = {
	class?: string;
	selectedCode: string;
	onChange: (code: string) => void;
};

// TODO: Factor out to a shared component
const CallingCodeSelect = (props: CallingCodeSelectProps) => {
	const countryCodes = COUNTRIES.toSorted((a, b) =>
		a.dial_code.localeCompare(b.dial_code),
	).map((c) => c.code);

	return (
		<Select
			class={props.class}
			options={countryCodes}
			disallowEmptySelection
			value={props.selectedCode}
			onChange={(v) => props.onChange(v!)}
			itemComponent={(_props) => (
				<Select.Item
					item={_props.item}
					class={cn(
						"text-4 flex items-center justify-between relative select-none outline-none px-16px py-12px",
						_props.item.rawValue === props.selectedCode && "bg-background-dark",
					)}
				>
					<Select.ItemLabel>
						{COUNTRY_MAP.get(_props.item.rawValue)?.emoji ?? ""}
						{"  "}
						{COUNTRY_MAP.get(_props.item.rawValue)?.callingCode}
					</Select.ItemLabel>
				</Select.Item>
			)}
		>
			<Select.Trigger class="flex w-full items-center justify-between rounded-full px-20px h-46px prose-sm bg-white text-primary outline-none shadow-regular">
				<Select.Value>
					{(state) => (
						<>
							{COUNTRY_MAP.get(state.selectedOption() as string)?.emoji ?? ""}
							{"  "}
							{COUNTRY_MAP.get(state.selectedOption() as string)?.callingCode}
						</>
					)}
				</Select.Value>
				<Select.Icon class="ml-2 inline-flex">
					<i class="inline-block i-bp-chevron-down" />
				</Select.Icon>
			</Select.Trigger>
			<Select.Portal>
				<Select.Content class="bg-white rounded-12px border-1px z-9999 shadow-[0px_4px_12px_0px_rgba(0,0,0,0.15)]">
					<Select.Listbox class="overflow-y-auto max-h-360px py-8px" />
				</Select.Content>
			</Select.Portal>
		</Select>
	);
};

type RecipientTypeSelectProps = {
	class?: string;
	type: RecipientType;
	onChange: (type: RecipientType) => void;
};

// TODO: Factor out to a shared component
const RecipientTypeSelect = (props: RecipientTypeSelectProps) => {
	return (
		<Select
			class={props.class}
			options={Object.values(RecipientType)}
			disallowEmptySelection
			value={props.type}
			onChange={(v) => props.onChange(v!)}
			itemComponent={(_props) => (
				<Select.Item
					item={_props.item}
					class={cn(
						"text-4 flex items-center justify-between relative select-none outline-none px-16px py-12px",
						_props.item.rawValue === props.type && "bg-background-dark",
					)}
				>
					<Select.ItemLabel class="capitalize">
						{_props.item.rawValue.toLowerCase()}
					</Select.ItemLabel>
				</Select.Item>
			)}
		>
			<Select.Trigger class="flex w-full items-center justify-between rounded-full px-20px h-46px prose-sm bg-white text-primary outline-none shadow-regular">
				<Select.Value class="capitalize">
					{props.type.toLowerCase()}
				</Select.Value>
				<Select.Icon class="ml-2 inline-flex">
					<i class="inline-block i-bp-chevron-down" />
				</Select.Icon>
			</Select.Trigger>
			<Select.Portal>
				<Select.Content class="bg-white rounded-12px border-1px z-9999 shadow-[0px_4px_12px_0px_rgba(0,0,0,0.15)]">
					<Select.Listbox class="overflow-y-auto max-h-360px py-8px" />
				</Select.Content>
			</Select.Portal>
		</Select>
	);
};

export default PayoutDetailsActivity;
