import { isIosSafari } from "@braintree/browser-detection";
import type { ComponentProps, JSX, ValidComponent } from "solid-js";
import { Show, splitProps } from "solid-js";

import type { PolymorphicProps } from "@kobalte/core";
import * as TextFieldPrimitive from "@kobalte/core/text-field";
import { cva } from "class-variance-authority";

import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { cn } from "~/lib/utils";

const TextField = TextFieldPrimitive.Root;

type TextFieldInputProps<T extends ValidComponent = "input"> =
	TextFieldPrimitive.TextFieldInputProps<T> & {
		class?: string | undefined;
		inputClass?: string | undefined;
		actions?: JSX.Element;
		type:
			| "button"
			| "checkbox"
			| "color"
			| "date"
			| "datetime-local"
			| "email"
			| "file"
			| "hidden"
			| "image"
			| "month"
			| "number"
			| "password"
			| "radio"
			| "range"
			| "reset"
			| "search"
			| "submit"
			| "tel"
			| "text"
			| "time"
			| "url"
			| "week";
	};

const TextFieldInput = (
	props: PolymorphicProps<"input", TextFieldInputProps<"input">>,
) => {
	const [local, others] = splitProps(props, [
		"ref",
		"type",
		"class",
		"inputClass",
		"actions",
		"onFocus",
		"onBlur",
	]);

	let input: HTMLInputElement | undefined;
	let canBlur = false;
	function onScroll() {
		// ref: https://stackoverflow.com/questions/43996535/how-to-fix-safaris-html-margin-bottom-bug-in-ios-10-3-1
		if (canBlur && isIosSafari()) input?.blur();
	}

	return (
		<div class={cn("relative", local.class)}>
			<TextFieldPrimitive.Input<"input">
				ref={(el) => {
					if (typeof local.ref === "function") local.ref(el);
					input = el;
				}}
				type={local.type}
				class={cn(
					"appearance-none flex w-full rounded-full bg-white px-20px py-15px prose-sm shadow-wide ring-offset-background placeholder:text-border-dark focus-visible:outline-none",
					"data-[invalid]:(ring-1 ring-functional-red-dark)",
					"disabled:(cursor-not-allowed opacity-50)",
					local.inputClass,
				)}
				onFocus={(e) => {
					if (typeof local.onFocus === "function") local.onFocus(e);
					if (!e.defaultPrevented) {
						canBlur = false;
						window.addEventListener("scroll", onScroll);
						setTimeout(() => {
							canBlur = true;
						}, 500);
					}
				}}
				onBlur={(e) => {
					if (typeof local.onBlur === "function") local.onBlur(e);
					if (!e.defaultPrevented) {
						window.removeEventListener("scroll", onScroll);
					}
				}}
				{...others}
			/>
			<Show when={local.actions}>
				{(actions) => (
					<div class="absolute inset-y-0 right-0 pl-3 pr-6 flex items-center bg-white rounded-r-full">
						{actions()}
					</div>
				)}
			</Show>
		</div>
	);
};

const labelVariants = cva(
	"prose-xs peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
	{
		variants: {
			variant: {
				label: "text-primary",
				description: "text-tertiary",
				error: "font-normal text-functional-red-dark",
			},
		},
		defaultVariants: {
			variant: "label",
		},
	},
);

type TextFieldLabelProps<T extends ValidComponent = "label"> =
	TextFieldPrimitive.TextFieldLabelProps<T> & { class?: string | undefined };

const TextFieldLabel = <T extends ValidComponent = "label">(
	props: PolymorphicProps<T, TextFieldLabelProps<T>>,
) => {
	const [local, others] = splitProps(props as TextFieldLabelProps, ["class"]);
	return (
		<TextFieldPrimitive.Label
			class={cn(labelVariants(), local.class)}
			{...others}
		/>
	);
};

type TextFieldDescriptionProps<T extends ValidComponent = "div"> =
	TextFieldPrimitive.TextFieldDescriptionProps<T> & {
		class?: string | undefined;
	};

const TextFieldDescription = <T extends ValidComponent = "div">(
	props: PolymorphicProps<T, TextFieldDescriptionProps<T>>,
) => {
	const [local, others] = splitProps(props as TextFieldDescriptionProps, [
		"class",
	]);
	return (
		<TextFieldPrimitive.Description
			class={cn(labelVariants({ variant: "description" }), local.class)}
			{...others}
		/>
	);
};

type TextFieldErrorMessageProps<T extends ValidComponent = "div"> =
	TextFieldPrimitive.TextFieldErrorMessageProps<T> & {
		class?: string | undefined;
	};

const TextFieldErrorMessage = <T extends ValidComponent = "div">(
	props: PolymorphicProps<T, TextFieldErrorMessageProps<T>>,
) => {
	const [local, others] = splitProps(props as TextFieldErrorMessageProps, [
		"class",
	]);
	return (
		<TextFieldPrimitive.ErrorMessage
			class={cn(labelVariants({ variant: "error" }), local.class)}
			{...others}
		/>
	);
};

type FormTextFieldProps = ComponentProps<typeof TextFieldInput> & {
	wrapperClass?: string | undefined;
	error?: string;
	label?: JSX.Element;
	description?: JSX.Element;
	bottomSuffix?: JSX.Element;
};

const FormTextField = (props: FormTextFieldProps) => {
	const [locals, others] = splitProps(props, [
		"wrapperClass",
		"error",
		"label",
		"description",
		"onFocus",
		"bottomSuffix",
	]);
	let ref: HTMLElement | undefined;

	return (
		<TextField
			ref={ref}
			validationState={locals.error ? "invalid" : "valid"}
			class={locals.wrapperClass}
		>
			<Show when={locals.label}>
				<TextFieldLabel class="block mb-10px">{locals.label}</TextFieldLabel>
			</Show>
			<TextFieldInput
				{...others}
				onFocus={async (e) => {
					if (typeof locals.onFocus === "function") locals.onFocus(e);
					if (Capacitor.isNativePlatform()) {
						const handle = await Keyboard.addListener("keyboardDidShow", () => {
							ref?.scrollIntoView({ behavior: "smooth", block: "center" });
							handle.remove();
						});
					}
				}}
			/>
			<Show when={locals.description && !locals.error}>
				<TextFieldDescription>
					<div class="flex items-center justify-between mt-10px">
						{locals.description}
						<Show when={locals.bottomSuffix}>{locals.bottomSuffix}</Show>
					</div>
				</TextFieldDescription>
			</Show>
			<Show when={locals.error}>
				<TextFieldErrorMessage>
					<div class="flex items-center justify-between mt-10px">
						{locals.error}
						<Show when={locals.bottomSuffix}>{locals.bottomSuffix}</Show>
					</div>
				</TextFieldErrorMessage>
			</Show>
		</TextField>
	);
};

export {
	TextField,
	TextFieldInput,
	TextFieldLabel,
	TextFieldDescription,
	TextFieldErrorMessage,
	FormTextField,
};
