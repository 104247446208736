import * as ButtonPrimitive from "@kobalte/core/button";
import type { PolymorphicProps } from "@kobalte/core/polymorphic";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import type { JSX, ValidComponent } from "solid-js";
import { splitProps } from "solid-js";
import { cn } from "~/lib/utils";

const buttonVariants = cva(
	"isolate inline-flex items-center justify-center gap-2 rounded-full transition-colors before:transition-opacity after:transition-colors disabled:pointer-events-none disabled:text-tertiary",
	{
		variants: {
			variant: {
				default:
					"bg-primary text-primary-foreground hover:bg-primary/90 disabled:bg-primary-disabled disabled:text-primary",
				highlighted: clsx(
					"relative overflow-hidden bg-transparent",
					"before:bg-highlighted before:absolute before:inset-0 before:-z-2 before:content-empty",
					"after:bg-primary-disabled after:absolute after:inset-0 after:-z-1 after:content-empty after:bg-opacity-0 disabled:after:bg-opacity-100",
					"text-highlighted-foreground hover:before:opacity-90",
				),
				offWhite:
					"bg-background-offWhite text-primary hover:bg-background-offWhite/90 disabled:bg-border-light disabled:text-border-dark",
				outline:
					"border border-border-dark text-primary disabled:border-border-light disabled:text-border-dark",
				text: "relative !px-12px !py-7px text-primary after:(absolute inset-x-12px bottom-7px h-1px content-empty bg-current)",
				"text-only": "relative !px-12px !py-7px text-primary",
				login:
					"bg-white text-primary shadow-wide flex justify-center items-center gap-10px active:hover:bg-background-off-white",
				ghost: "hover:bg-[#F5F5F5] hover:text-[#171717]",
			},
			size: {
				default: "px-[24px] py-[13px] prose-md-b",
				sm: "px-[16px] py-[9px] prose-sm-b",
				xs: "px-16px py-7px prose-xs-b",
				desktop: "px-16px py-12px prose-sm-b only-child:min-w-120px",
				circular: "p-10px",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
);

type ButtonProps<T extends ValidComponent = "button"> =
	ButtonPrimitive.ButtonRootProps<T> &
		VariantProps<typeof buttonVariants> & {
			class?: string | undefined;
			children?: JSX.Element;
		};

const Button = <T extends ValidComponent = "button">(
	props: PolymorphicProps<T, ButtonProps<T>>,
) => {
	const [local, others] = splitProps(props as ButtonProps, [
		"variant",
		"size",
		"class",
	]);
	return (
		<ButtonPrimitive.Root
			class={cn(
				buttonVariants({ variant: local.variant, size: local.size }),
				local.class,
			)}
			{...others}
		/>
	);
};

export type { ButtonProps };
export { Button, buttonVariants };
