import { cacheExchange } from "@urql/exchange-graphcache";
import { relayPagination } from "@urql/exchange-graphcache/extras";
import { createClient, fetchExchange } from "@urql/solid";
import { P, match } from "ts-pattern";
import * as v from "valibot";
import { FavoritesQuery } from "~/activities/root/music";
import type {
	FavoriteMusicMutation,
	UnfavoriteMusicMutation,
} from "~/activities/root/music";
import {
	PayoutDetailsFragment,
	type UpdatePayoutDetailsMutation,
} from "~/activities/settings/payoutDetails";
import { session } from "~/lib/supabase";
import { type ResultOf, graphql } from "./tada";

export const urqlClient = createClient({
	url: new URL("/graphql", import.meta.env.VITE_API_BASE).toString(),
	fetchOptions: () => {
		return {
			headers: {
				authorization: session() ? `Bearer ${session()?.access_token}` : "",
			},
		};
	},
	suspense: true,
	exchanges: [
		cacheExchange({
			resolvers: {
				Query: {
					musics: relayPagination(),
				},
			},
			keys: {
				MoodStats: (data) => String(data.mood) ?? null,
				LocalizedString: () => null,
				ChannelMetrics: () => null,
				ChannelStats: () => null,
				StatsAggregate: () => null,
				Payout: () => null,
			},
			updates: {
				Mutation: {
					fetchYoutube: (result, _, cache) => {
						const data = v.parse(
							v.object({
								fetchYoutube: v.union([
									v.object({
										__typename: v.literal("FetchYoutubeSingularPayload"),
										channel: v.object({
											__typename: v.literal("Channel"),
											id: v.string(),
											name: v.string(),
											profileImage: v.string(),
										}),
									}),
									v.object({ __typename: v.string() }),
								]),
							}),
							result,
						);

						match(data.fetchYoutube)
							.with({ channel: P.select() }, (channel) => {
								const query = graphql(`
									query _ {
										ownedChannels {
											__typename
											id
											name
											profileImage
										}
									}
								`);
								cache.updateQuery({ query }, (data) => {
									if (!data?.ownedChannels.some((c) => c.id === channel.id)) {
										data?.ownedChannels.push(channel);
									}
									return data;
								});
							})
							.otherwise(() => {});
					},
					favoriteMusic: (
						result: ResultOf<typeof FavoriteMusicMutation>,
						_,
						cache,
					) => {
						if (result.mutate?.__typename !== "FavoriteMusicPayload") return;

						const musicNode = result.mutate.music;

						cache.updateQuery(
							{
								query: FavoritesQuery,
								variables: { first: 10, after: null, mood: null },
							},
							(data) => {
								if (!data) return data;

								const exists = data.musics.edges.some(
									(edge) => edge.node.id === musicNode.id,
								);
								if (exists) return data;

								// Add the music item to the end of favorites
								return {
									...data,
									musics: {
										...data.musics,
										edges: [
											...data.musics.edges,
											{
												node: musicNode,
												__typename: "MusicEdge",
											},
										],
									},
								};
							},
						);
					},
					unfavoriteMusic: (
						result: ResultOf<typeof UnfavoriteMusicMutation>,
						_,
						cache,
					) => {
						if (result.mutate?.__typename !== "UnfavoriteMusicPayload") return;
						const musicNode = result.mutate.music;

						cache.updateQuery(
							{
								query: FavoritesQuery,
								variables: { first: 10, after: null, mood: null },
							},
							(data) => {
								if (!data) return data;

								return {
									...data,
									musics: {
										...data.musics,
										edges: data.musics.edges.filter(
											(edge) => edge.node.id !== musicNode.id,
										),
									},
								};
							},
						);
					},
					updatePayoutDetails: (
						result: ResultOf<typeof UpdatePayoutDetailsMutation>,
						_,
						cache,
					) => {
						// validate only, don't use the output
						v.parse(
							v.object({
								updatePayoutDetails: v.union([
									v.object({
										__typename: v.literal("UpdatePayoutDetailsPayload"),
										payoutDetails: v.looseObject({
											__typename: v.literal("PayoutDetails"),
										}),
									}),
									v.object({ __typename: v.string() }),
								]),
							}),
							result,
						);

						match(result.updatePayoutDetails).with(
							{ payoutDetails: P.nonNullable },
							({ payoutDetails }) => {
								const query = graphql(
									`
										query _ {
											payoutDetails {
												...PayoutDetails
											}
										}
									`,
									[PayoutDetailsFragment],
								);
								cache.updateQuery({ query }, (prev) => ({
									...prev,
									payoutDetails,
								}));
							},
						);
					},
				},
			},
			optimistic: {
				favoriteMusic: (args) => ({
					__typename: "FavoriteMusicPayload",
					music: {
						__typename: "Music",
						id: (args as { input: { musicId: string } }).input.musicId,
						isFavorite: true,
					},
				}),
				unfavoriteMusic: (args) => ({
					__typename: "UnfavoriteMusicPayload",
					music: {
						__typename: "Music",
						id: (args as { input: { musicId: string } }).input.musicId,
						isFavorite: false,
					},
				}),
			},
		}),
		// import.meta.env.PROD &&
		// 	persistedExchange({
		// 		enableForMutation: true,
		// 		enforcePersistedQueries: true,
		// 		generateHash: async (_, document) =>
		// 			(document as PersistedDocument).documentId.replace(/^sha256:/, ""),
		// 	}),
		fetchExchange,
	].filter((v) => !!v),
});
