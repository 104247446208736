import Tooltip from "@corvu/tooltip";
import { RadioGroup } from "@kobalte/core/radio-group";
import {
	For,
	Show,
	Suspense,
	createMemo,
	createSignal,
	useTransition,
} from "solid-js";
import { Intl, Temporal } from "temporal-polyfill";
import { Divider } from "~/components/ui/divider";
import {
	Drawer,
	DrawerContent,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "~/components/ui/drawer";
import { Skeleton } from "~/components/ui/skeleton";
import { createDebounced } from "~/lib/createDebounced";
import { createDeferredValue } from "~/lib/createDeferredValue";
import { environment } from "~/lib/environment";
import { createToeQuery } from "~/lib/gql/createToeQuery";
import { graphql, readFragment } from "~/lib/gql/tada";
import { latest } from "~/lib/latest";
import { cn } from "~/lib/utils";
import { MetricChart } from "./metricChart";

export const CurrentMetricsFragment = graphql(`
	fragment CurrentMetrics on ChannelMetrics {
		from
		to
		totalViews {
			aggregate {
				value: sum
			}
			daily
		}
		premiumSubscriberViews {
			aggregate {
				value: sum
			}
			daily
		}
		estimatedPayouts {
			aggregate {
				value: sum
			}
			daily
		}
		rpm {
			aggregate {
				value: mean
			}
			daily
		}
		totalWatchTime: duration {
			aggregate {
				value: sum
			}
			daily
		}
		avgWatchTime: duration {
			aggregate {
				value: mean
			}
			daily
		}
	}
`);

export const PreviousMetricsFragment = graphql(`
	fragment PreviousMetrics on ChannelMetrics {
		totalViews {
			aggregate {
				value: sum
			}
		}
		premiumSubscriberViews {
			aggregate {
				value: sum
			}
		}
		estimatedPayouts {
			aggregate {
				value: sum
			}
		}
		rpm {
			aggregate {
				value: mean
			}
		}
		totalWatchTime: duration {
			aggregate {
				value: sum
			}
		}
		avgWatchTime: duration {
			aggregate {
				value: mean
			}
		}
	}
`);

export const ChannelMetricsQuery = graphql.persisted(
	"sha256:975309ac8ab325b42c2fb85977d6b844507c53f11c619669458f8b494ddf8746",
	graphql(
		`
			query ChannelMetrics(
				$channelId: ID!
				$currentFilter: ChannelMetricsFilter!
				$previousFilter: ChannelMetricsFilter!
			) {
				node(id: $channelId) {
					__typename
					id
					... on Channel {
						currentMetrics: metrics(filter: $currentFilter) {
							...CurrentMetrics
						}
						previousMetrics: metrics(filter: $previousFilter) {
							...PreviousMetrics
						}
					}
				}
			}
		`,
		[CurrentMetricsFragment, PreviousMetricsFragment],
	),
);

type DashboardMetricInfo = {
	title: string;
	shortTitle?: string;
	description: (duration: string) => string;
	defaultValue: number;
	formatValue?: (value: number) => string;
};

const _KeyMetrics = {
	totalViews: {
		title: "Total Views",
		description: (duration) =>
			`The number of people who viewed your Shorts compared to ${duration}`,
		defaultValue: 0,
		formatValue: (v) => `${v.toLocaleString()}`,
	},
	premiumSubscriberViews: {
		title: "Premium subscriber's views",
		shortTitle: "Premium sub. views",
		description: (duration) =>
			`The number of YouTube Premium subscribers who viewed your Shorts compared to ${duration}`,
		defaultValue: 0,
		formatValue: (v) => `${v.toLocaleString()}`,
	},
	estimatedPayouts: {
		title: "Estimated payouts",
		shortTitle: "Est. payouts",
		description: (duration) =>
			`The estimated earning from your Shorts, based on total views and the percentage of views from YouTube Premium subscribers compared to ${duration}`,
		defaultValue: 0,
		formatValue: (v) =>
			v.toLocaleString(undefined, {
				style: "currency",
				currency: "USD",
				currencyDisplay: "narrowSymbol",
			}),
	},
	rpm: {
		title: "RPM",
		description: (duration) =>
			`The estimated earnings per 1,000 views compared to the period from ${duration}`,
		formatValue: (v) =>
			v.toLocaleString(undefined, {
				style: "currency",
				currency: "USD",
				currencyDisplay: "narrowSymbol",
				maximumFractionDigits: 5,
			}),
		defaultValue: 0,
	},
	totalWatchTime: {
		title: "Total watch time",
		description: (duration) =>
			`The total amount of time viewers spent watching your Shorts compared to ${duration}`,
		defaultValue: 0,
		formatValue: (v) => {
			const duration = Temporal.Duration.from({
				seconds: Math.round(v),
			}).round({ largestUnit: "hour" });

			if (duration.hours >= 10000) {
				return `${duration.hours.toLocaleString()} hours`;
			}

			return new globalThis.Intl.DurationFormat("en", {
				style: "digital",
				// @ts-expect-error Intl.DurationFormat is not fully supported in TypeScript
				hours: "2-digit",
				hoursDisplay: "always",
			})
				.format(duration)
				.replace(/^:, /, ""); // NOTE: workaround Safari 18 bug;
		},
	},
	avgWatchTime: {
		title: "Average watch time",
		shortTitle: "Avg. watch time",
		description: (duration) =>
			`The average duration that each viewer spent watching your Shorts compared to ${duration}`,
		defaultValue: 0,
		formatValue: (v) =>
			new globalThis.Intl.DurationFormat("en", {
				style: "digital",
				hoursDisplay: "auto",
			})
				.format(
					Temporal.Duration.from({ seconds: Math.round(v) }).round({
						largestUnit: "hour",
					}),
				)
				.replace(/^:, /, ""), // NOTE: workaround Safari 18 bug
	},
} satisfies Record<string, DashboardMetricInfo>;
type DashboardMetric = keyof typeof _KeyMetrics;

const KeyMetrics = _KeyMetrics as Record<DashboardMetric, DashboardMetricInfo>;

const MetricInfo = (props: {
	dataRangeText: string;
}) => {
	const content = () => (
		<div class="flex flex-col gap-5 env-desktop:(gap-4)">
			<For each={Object.values(KeyMetrics)}>
				{(info) => (
					<div class="flex flex-col gap-1 env-desktop:(gap-1.5)">
						<h3 class="text-primary prose-sm-b">{info.title}</h3>
						<p class="text-secondary prose-xs env-desktop:(leading-[1.4])">
							{info.description(props.dataRangeText)}
						</p>
					</div>
				)}
			</For>
			<Show when={environment === "mobile"}>
				<Divider size="full" class="mx-[-1.5rem]" />
			</Show>
			<div class="flex flex-row gap-10px ">
				<i class="flex-shrink-0 i-bp-info size-16px text-functional-blue" />
				<p class="text-functional-blue prose-2xs leading-[1.3]">
					Views and earnings from YouTube Shorts are reported based on data from
					two days prior (D-2). This means the numbers reflect activity up to
					two days ago, and the final payout may differ slightly from the
					estimated earnings displayed, as updates and adjustments could occur.
				</p>
			</div>
		</div>
	);
	return environment === "mobile" ? (
		<Drawer>
			<DrawerTrigger class="flex items-center">
				<i class="inline-block i-bp-info text-tertiary" />
			</DrawerTrigger>
			<DrawerContent>
				<DrawerHeader>
					<DrawerTitle>Key metrics</DrawerTitle>
				</DrawerHeader>
				<div class="px-4 py-6 ">{content()}</div>
			</DrawerContent>
		</Drawer>
	) : (
		<Tooltip
			placement="bottom-start"
			openDelay={100}
			closeOnPointerDown={false}
			floatingOptions={{
				offset: 13,
				shift: true,
				size: {
					fitViewPort: true,
				},
			}}
		>
			<Tooltip.Anchor class="flex justify-center items-center">
				<Tooltip.Trigger class="flex">
					<i class="inline-block i-bp-info size-16px text-tertiary" />
				</Tooltip.Trigger>
			</Tooltip.Anchor>
			<Tooltip.Portal>
				<Tooltip.Content class="relative rounded-12px bg-white z-999 w-420px shadow-[0px_4px_12px_0px_rgba(0,0,0,0.15)] max-h-200px overflow-y-auto">
					<div class="sticky top-0 bg-white px-20px pt-20px">
						<h2 class="text-primary prose-md-b">Key metrics</h2>
						<Divider size="full" class="mr-6 my-4" />
					</div>
					<div class="px-20px pb-20px">{content()}</div>
				</Tooltip.Content>
			</Tooltip.Portal>
		</Tooltip>
	);
};

const durationFormat = Intl.DateTimeFormat("en", {
	month: "short",
	day: "numeric",
});

type AnalyticsProps = {
	selectedChannelId?: string;
	selectedDateRange: [Temporal.PlainDate, Temporal.PlainDate];
};

export const ChannelAnalyticsContainer = (props: AnalyticsProps) => {
	const [selectedMetric, setSelectedMetric] = createSignal<
		DashboardMetric | undefined
	>(Object.keys(KeyMetrics)[0] as DashboardMetric);
	const [isPending] = useTransition();
	const isPendingDebounced = createDebounced(isPending, 50);
	const metricFilters = createDeferredValue(
		createMemo(() =>
			props.selectedDateRange &&
			props.selectedDateRange[0].until(props.selectedDateRange[1]).days > 0
				? {
						currentFilter: {
							range: {
								from: props.selectedDateRange[0].toString(),
								to: props.selectedDateRange[1].toString(),
							},
						},
						previousFilter: {
							range: {
								from: props.selectedDateRange[0]
									.subtract({
										days: props.selectedDateRange[1].since(
											props.selectedDateRange[0],
										).days,
									})
									.toString(),
								to: props.selectedDateRange[0].subtract({ days: 1 }).toString(),
							},
						},
					}
				: undefined,
		),
	);

	const [query] = createToeQuery({
		query: ChannelMetricsQuery,
		variables: () => ({
			...metricFilters()!,
			channelId: props.selectedChannelId!,
		}),
		pause: () => !props.selectedChannelId || !metricFilters(),
	});

	const activeMetrics = () => {
		const channel = latest(() => query.data?.node);
		if (!channel || channel.__typename !== "Channel") return undefined;
		return {
			current: readFragment(CurrentMetricsFragment, channel.currentMetrics),
			previous: readFragment(PreviousMetricsFragment, channel.previousMetrics),
		};
	};

	const dataDateRangeText = () => {
		const selectedDateRange = props.selectedDateRange;
		if (!selectedDateRange) return "\u00A0"; // empty string

		return durationFormat
			.formatRange(selectedDateRange[0], selectedDateRange[1])
			.replace("–", "-");
	};

	return (
		<div class="flex flex-col gap-4">
			<div class="flex flex-col gap-4 env-desktop:(flex-row-reverse)">
				<div class="flex-grow-1 flex flex-col gap-6px">
					<div class="flex gap-6px align-center">
						<h2 class="text-primary prose-lg-b">Key metrics</h2>
						<MetricInfo dataRangeText={dataDateRangeText()} />
					</div>

					<span class="text-tertiary prose-sm">{dataDateRangeText()}</span>
				</div>
			</div>
			<RadioGroup
				value={selectedMetric()}
				onChange={setSelectedMetric}
				class="grid grid-cols-2 gap-8px env-desktop:(grid-cols-3 gap-16px)"
			>
				<For
					each={(() => {
						// TODO: Factor out the metric logic. It looks complex and less readable
						return Object.entries(KeyMetrics).map(([id, info]) => {
							const metric = id as DashboardMetric;
							const enabled = () => selectedMetric() === metric;
							const value = () => ({
								current: (activeMetrics()?.current[metric]?.aggregate.value ??
									null) as number | null,
								previous: (activeMetrics()?.previous[metric]?.aggregate.value ??
									null) as number | null,
							});

							const diffRatio = () => {
								const v = value();
								if (v.current == null || v.previous == null || v.previous === 0)
									return null;
								return ((v.current - v.previous) / Math.abs(v.previous)) * 100;
							};
							return {
								id,
								info,
								enabled,
								value,
								diffRatio,
							};
						});
					})()}
				>
					{({ id, info, enabled, value, diffRatio }) => (
						<div class="h-80px env-desktop:(h-96px)">
							<RadioGroup.Item
								value={id}
								class={cn(
									"relative h-full p-10px env-desktop:(p-20px) transition-all rounded-6px cursor-pointer",
									enabled() ? "bg-white" : "bg-background-dark",
								)}
							>
								<Show when={enabled()}>
									<div class="absolute inset-0 rounded-6px border-1 border-primary" />
								</Show>
								<RadioGroup.ItemInput />
								<RadioGroup.ItemControl class="h-full flex flex-col justify-between">
									<h3 class="text-primary prose-xs env-desktop:(prose-sm)">
										{environment === "mobile"
											? (info.shortTitle ?? info.title)
											: info.title}
									</h3>
									<div class="flex flex-col gap-1 env-desktop:(flex-row justify-between flex-wrap)">
										<Suspense fallback={<Skeleton />}>
											<Show
												when={query.data}
												fallback={
													<p class="text-primary prose-md-b env-desktop:(prose-xl-b)">
														0
													</p>
												}
											>
												<p class="text-primary prose-md-b env-desktop:(prose-xl-b)">
													{info.formatValue?.(
														value().current ?? info.defaultValue,
													) ??
														value().current ??
														info.defaultValue}
												</p>
												<div
													class={cn(
														diffRatio() == null && "invisible", // NOTE: This is a workaround to handle a variety of cases instead of using Show
														"prose-2xs env-desktop:(prose-md) flex gap-1 items-center",
														(diffRatio() ?? 0) > 0
															? "text-functional-green-dark"
															: "text-functional-red-dark",
														"gap-1 env-desktop:(gap-1.5)",
													)}
												>
													<i
														class={cn(
															"inline-block",
															(diffRatio() ?? 0) > 0
																? "i-bp-increase"
																: "i-bp-decrease",
															"size-8px env-desktop:(size-12px)",
														)}
													/>
													{Math.abs(diffRatio() ?? 0).toFixed(1)}%
												</div>
											</Show>
										</Suspense>
									</div>
								</RadioGroup.ItemControl>
							</RadioGroup.Item>
						</div>
					)}
				</For>
			</RadioGroup>
			<div class="h-190px env-desktop:(h-274px)">
				<MetricChart
					range={{
						from: props.selectedDateRange[0],
						to: props.selectedDateRange[1],
					}}
					// TODO: Reduce re-renders
					data={(() => {
						const metric = selectedMetric();
						if (!metric) return [];

						// TODO: Currently, avgWatchTime is the only metric that doesn't have a proper daily value (it's the same as totalWatchTime's daily value)
						// This should be fixed in the backend
						if (metric === "avgWatchTime") {
							return (
								activeMetrics()?.current.totalWatchTime?.daily.map((v, idx) => {
									const _v = v ?? 0;
									const _tv =
										activeMetrics()?.current.totalViews.daily[idx] ?? 0;
									return _tv === 0 ? 0 : _v / _tv;
								}) ?? []
							);
						}

						return (
							activeMetrics()?.current?.[metric]?.daily.map((v) => v ?? 0) ?? []
						);
					})()}
					class={cn(
						"transition-opacity w-100%",
						isPendingDebounced() && "opacity-50",
						"env-desktop:(rounded-16px border-1 border-border-light)",
					)}
				/>
			</div>
		</div>
	);
};
