import type { DateValue } from "@internationalized/date";
import { Index, Show, createEffect, createMemo, createSignal } from "solid-js";
import { Temporal } from "temporal-polyfill";
import { Button } from "~/components/ui/button";
import {
	CalendarDate,
	DatePicker,
	DatePickerContent,
	DatePickerContext,
	DatePickerRangeText,
	DatePickerTable,
	DatePickerTableBody,
	DatePickerTableCell,
	DatePickerTableCellTrigger,
	DatePickerTableHead,
	DatePickerTableHeader,
	DatePickerTableRow,
	DatePickerView,
	DatePickerViewControl,
	DatePickerViewTrigger,
} from "~/components/ui/datePicker";
import { Divider } from "~/components/ui/divider";

interface CustomDatePickerProps {
	maxDate?: Temporal.PlainDate;
	selectedDateRange: [Temporal.PlainDate, Temporal.PlainDate] | undefined;
	onDateRangeSelected: (
		value: [Temporal.PlainDate, Temporal.PlainDate],
	) => void;
}

export const DateRangePicker = (props: CustomDatePickerProps) => {
	const [selectedDateRange, setSelectedDateRange] = createSignal<
		[DateValue, DateValue] | undefined
	>(undefined);

	createEffect(() => {
		const range = props.selectedDateRange;
		setSelectedDateRange(
			range
				? [
						new CalendarDate(range[0].year, range[0].month, range[0].day),
						new CalendarDate(range[1].year, range[1].month, range[1].day),
					]
				: undefined,
		);
	});
	createEffect(() => {
		console.log("SELECTED DATE RANGE", selectedDateRange());
	});

	return (
		<div class="flex flex-col gap-10px">
			<DatePicker
				open
				selectionMode="range"
				onValueChange={({ value }) => {
					const [from, to] = value as unknown as [CalendarDate, CalendarDate];
					if (from === undefined || to === undefined) {
						return;
					}
					setSelectedDateRange([from, to]);
				}}
				isDateUnavailable={(date) => {
					const until = props.maxDate;
					if (until == null) return false;
					const _date = Temporal.PlainDate.from({
						year: date.year,
						month: date.month,
						day: date.day,
					});

					return Temporal.PlainDate.compare(_date, until) > 0;
				}}
				// TODO: we should adjust the type of value, DateValue. Currently, it's functioning without issues using CalendarDate
				// @ts-expect-error ignored
				value={selectedDateRange() ?? []}
			>
				<DatePickerContent class="border-none shadow-none p-0">
					<DatePickerView view="day">
						<DatePickerContext>
							{(context) => (
								<>
									<DatePickerViewControl>
										<DatePickerViewTrigger>
											<DatePickerRangeText />
										</DatePickerViewTrigger>
									</DatePickerViewControl>
									<DatePickerTable>
										<DatePickerTableHead>
											<DatePickerTableRow>
												<Index each={context().weekDays}>
													{(weekDay) => (
														<DatePickerTableHeader>
															{weekDay().short}
														</DatePickerTableHeader>
													)}
												</Index>
											</DatePickerTableRow>
										</DatePickerTableHead>
										<DatePickerTableBody>
											<Index each={context().weeks}>
												{(week) => (
													<DatePickerTableRow>
														<Index each={week()}>
															{(day) => (
																<DatePickerTableCell value={day()}>
																	<DatePickerTableCellTrigger>
																		{day().day}
																	</DatePickerTableCellTrigger>
																</DatePickerTableCell>
															)}
														</Index>
													</DatePickerTableRow>
												)}
											</Index>
										</DatePickerTableBody>
									</DatePickerTable>
								</>
							)}
						</DatePickerContext>
					</DatePickerView>
				</DatePickerContent>
			</DatePicker>
			<Divider size="full" />

			<Show when={selectedDateRange()}>
				{(range) => {
					const fromPlainDate = createMemo(() => {
						const [from] = range();
						return Temporal.PlainDate.from({
							year: from.year,
							month: from.month,
							day: from.day,
						});
					});

					const toPlainDate = createMemo(() => {
						const [, to] = range();
						return Temporal.PlainDate.from({
							year: to.year,
							month: to.month,
							day: to.day,
						});
					});

					const days = createMemo(
						() => fromPlainDate().until(toPlainDate()).days + 1,
					);

					return (
						<div class="text-primary prose-md-b text-center my-10px">
							{fromPlainDate().toLocaleString("en-US", {
								month: "short",
								day: "numeric",
							})}{" "}
							-{" "}
							{toPlainDate().toLocaleString("en-US", {
								...(fromPlainDate().monthCode === toPlainDate().monthCode
									? {}
									: { month: "short" }),
								day: "numeric",
							})}{" "}
							({days()} days)
						</div>
					);
				}}
			</Show>
			<Button
				disabled={!selectedDateRange()}
				variant="highlighted"
				onClick={() => {
					props.onDateRangeSelected([
						Temporal.PlainDate.from({
							year: selectedDateRange()![0].year,
							month: selectedDateRange()![0].month,
							day: selectedDateRange()![0].day,
						}),
						Temporal.PlainDate.from({
							year: selectedDateRange()![1].year,
							month: selectedDateRange()![1].month,
							day: selectedDateRange()![1].day,
						}),
					]);
				}}
			>
				Apply
			</Button>
		</div>
	);
};
