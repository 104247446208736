import { createMutation } from "@urql/solid";
import { P, match } from "ts-pattern";
import { useTopAlert } from "~/components/ui/topAlert";
import { graphql } from "~/lib/gql/tada";
import { MixpanelEvent, trackEvent } from "../mixpanel";

const DeleteYoutubeChannelMutation = graphql.persisted(
	"sha256:ae5f837488a0f5c8138af2809c2cc09b89c85694be54e08914e33f990bfeaf6c",
	graphql(`
		mutation RemoveYoutubeChannel($input: RemoveOwnedChannelInput!) {
			removeOwnedChannel(input: $input) {
				... on RemoveOwnedChannelPayload {
                    removedChannelId
                }
                ... on NotFoundError {
					message
                }
				... on UnknownError {
					message
                }
			}
		}
	`),
);

export const useDeleteChannel = () => {
	const [, deleteYoutube] = createMutation(DeleteYoutubeChannelMutation);
	const topAlert = useTopAlert();

	return async (id: string) => {
		const removedChannelId = await deleteYoutube({
			input: { id },
		})
			.then((res) =>
				match(res.data?.removeOwnedChannel)
					.with(P.nullish, () => undefined)
					.with(
						{ removedChannelId: P.nonNullable },
						({ removedChannelId }) => removedChannelId,
					)
					.otherwise(({ __typename }) => __typename),
			)
			.catch(() => undefined);

		trackEvent(MixpanelEvent.ChannelDeleted, {
			is_success: removedChannelId !== undefined,
			channel_id: removedChannelId,
		});

		if (typeof removedChannelId === "string") {
			topAlert.open({
				variant: "success",
				children: <p>Successfully deleted YouTube channel</p>,
			});
		} else {
			topAlert.open({
				variant: "error",
				children: (
					<div>
						<p>Failed to delete YouTube channel</p>
						<p>Please try again</p>
					</div>
				),
			});
		}
	};
};
