import type { ActivityDefinition, Config } from "@stackflow/config";
import * as v from "valibot";

declare module "@stackflow/config" {
	interface ActivityDefinition<ActivityName extends string> {
		omniflowPath?: {
			childRequired?: boolean;
			childParamName?: string;
			segment?: string;
		};
	}
}

export function wrapConfigWithOmniPath<
	const T extends Config<D>,
	D extends ActivityDefinition<string>,
>(environment: string, config: T): T {
	const pathSegmentsMap = new Map<string, string>(
		config.activities
			.map((activity) => {
				if (!activity.omniflowPath?.segment) return;
				return [activity.name, activity.omniflowPath?.segment] as const;
			})
			.filter((v) => v != null),
	);
	const activityMap = new Map<string, D>(
		config.activities.map((activity) => [activity.name, activity] as const),
	);

	config.activities = config.activities.map((activity) => {
		if (!activity.omniflow) return activity;

		const makeChildToPathSegmentEntries = (activity: D): [string, string][] => {
			const children = activity.omniflow?.[environment]?.subview?.children;
			if (!children) return [];
			return children.flatMap((c) => {
				const pathSegment = pathSegmentsMap.get(c);
				if (!pathSegment) {
					throw new Error(
						`Path segment not found for child: "${c}". Please add \`omniflowPath.segment\` to the Stackflow activity config.`,
					);
				}
				const activity = activityMap.get(c);
				return [
					[c, pathSegment],
					...(activity ? makeChildToPathSegmentEntries(activity) : []),
				] as const;
			});
		};

		const codec = activity.omniflowPath?.segment
			? undefined
			: makeOmniPathCodec({
					childRequired: activity.omniflowPath?.childRequired,
					childParamName: activity.omniflowPath?.childParamName,
					childToPathSegment: Object.fromEntries(
						makeChildToPathSegmentEntries(activity),
					),
				});

		return {
			...activity,
			...codec,
		};
	});

	return config;
}

type OmniPathOptions = {
	childToPathSegment: Partial<Record<string, string>>;
	childRequired?: boolean;
	childParamName?: string;
};

const makeNonOptionalIfRequired =
	(required: boolean) =>
	<T extends v.BaseSchema<unknown, unknown, v.BaseIssue<unknown>>>(
		schema: T,
	): v.OptionalSchema<T, undefined> =>
		(required ? schema : v.optional(schema)) as v.OptionalSchema<T, undefined>;

const makeOmniPathCodec = (options: OmniPathOptions) => ({
	encodePath: (params: Record<string, unknown>) => {
		const nonOptionalIfRequired = makeNonOptionalIfRequired(
			options?.childRequired ?? true,
		);

		const { OMNI_childName, OMNI_childParams, ...rest } = v.parse(
			v.objectWithRest(
				{
					OMNI_childName: nonOptionalIfRequired(
						v.pipe(
							v.string(),
							v.transform((json) => JSON.parse(json) as unknown),
							v.array(v.string()),
						),
					),
					OMNI_childParams: nonOptionalIfRequired(
						v.pipe(
							v.string(),
							v.transform((json) => JSON.parse(json) as unknown),
							v.array(v.record(v.string(), v.string())),
						),
					),
				},
				v.string(),
			),
			params,
		);
		return {
			...rest,
			[options?.childParamName ?? "_"]: OMNI_childName?.map(
				(name) => options.childToPathSegment[name],
			)
				.filter(Boolean)
				.join("/"),
			p: JSON.stringify(OMNI_childParams),
		} as Record<string, string>;
	},
	decodePath: (params: Record<string, unknown>) => {
		const nonOptionalIfRequired = makeNonOptionalIfRequired(
			options?.childRequired ?? true,
		);
		const parseResult = v.safeParse(
			v.objectWithRest(
				{
					[options?.childParamName ?? "_"]: nonOptionalIfRequired(
						v.pipe(v.string(), v.nonEmpty()),
					),
					p: v.optional(v.string()),
				},
				v.string(),
			),
			params,
		);
		if (!parseResult.success) return null;

		const {
			[options?.childParamName ?? "_"]: children,
			p,
			...rest
		} = parseResult.output;
		const childName = children
			?.split("/")
			.map((name) =>
				Object.keys(options.childToPathSegment).find(
					(key) => options.childToPathSegment[key] === name,
				),
			);
		if (children && !childName?.every(Boolean)) return null;
		return {
			...rest,
			OMNI_childName: JSON.stringify(childName),
			OMNI_childParams:
				p ??
				(childName ? `[${childName.map(() => "{}").join(",")}]` : undefined),
		};
	},
});
