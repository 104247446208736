export const HELP_CENTER_ROOT_URL = "https://help.backpac.app/";
export const PRIVACY_POLICY_URL =
	"https://help.backpac.app/Privacy-Policy-5d0d822092d84ff5a843fe83ce7bc1a5";
export const TERMS_OF_SERVICE_URL =
	"https://help.backpac.app/Terms-of-Service-1c4c288f38db4cb993a1f54bd7a1a73a";
export const FEEDBACK_FORM_LINK =
	"https://2nvl2chc7pe.typeform.com/to/h9RAFCVe";

export const YOUTUBE_SHORTS_BASE_DEEPLINK_URL =
	"vnd.youtube://m.youtube.com/source";
export const generateYoutubeShortsDeeplink = (videoId: string) =>
	`${YOUTUBE_SHORTS_BASE_DEEPLINK_URL}/${videoId}/shorts`;
