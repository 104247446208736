import { Button as RawButton } from "@kobalte/core/button";
import { createAudio } from "@solid-primitives/audio";
import { createMutation } from "@urql/solid";
import {
	Show,
	Suspense,
	createEffect,
	createMemo,
	createRoot,
	createSignal,
	untrack,
} from "solid-js";
import { Temporal } from "temporal-polyfill";
import { useNavContext } from "~/activities/root";
import {
	FavoriteMusicMutation,
	UnfavoriteMusicMutation,
} from "~/activities/root/music";
import { environment } from "~/lib/environment";
import { type FragmentOf, graphql, readFragment } from "~/lib/gql/tada";
import { MixpanelEvent, TrackingWrapper } from "~/lib/mixpanel";
import { cn } from "~/lib/utils";
import { Image } from "./ui/image";
import {
	YoutubeActionButton,
	YoutubeActionButtonFragment,
} from "./youtubeActionButton";

const PLAYER_HEIGHT = {
	mobile: 64,
	desktop: 70,
}[environment];
const PREV_THRESHOLD = 3;

type MusicPlayerProps = {
	$music: FragmentOf<typeof MusicPlayerFragment> | undefined;
	isPlaying: boolean;
	onPlayingChange: (playing: boolean) => void;
	onPrev?: () => void;
	onNext?: () => void;
};

export const MusicPlayerFragment = graphql(
	`
		fragment MusicPlayer on Music {
			id
			title {
				en
			}
			coverImage
			isFavorite
			audio {
				url
				mimeType
			}
			...YoutubeActionButton
		}
	`,
	[YoutubeActionButtonFragment],
);

const durationFormat = new Intl.DurationFormat(undefined, {
	minutes: "numeric",
	seconds: "2-digit",
});

export const MusicPlayer = (props: MusicPlayerProps) => {
	const [ref, setRef] = createSignal<HTMLDivElement>();
	const music = createMemo(() =>
		readFragment(MusicPlayerFragment, props.$music),
	);
	const audioEl = createMemo(() => {
		const audioList = music()?.audio.slice();
		if (!audioList?.length) return;
		const audio = document.createElement("audio");
		audio.append(
			...audioList.map((file) => {
				const source = document.createElement("source");
				source.src = new URL(
					file.url,
					import.meta.env.VITE_API_BASE,
				).toString();
				source.type = file.mimeType;
				return source;
			}),
		);
		audio.addEventListener("ended", () => {
			props.onPlayingChange(false);
		});

		return audio;
	});

	const [audio, controls] = createAudio(audioEl, () => props.isPlaying);

	// Force to play the audio when music is set or changed
	createEffect(() => {
		if (music()) props.onPlayingChange(true);
	});

	const nav = useNavContext();
	const [, favoriteMusic] = createMutation(FavoriteMusicMutation);
	const [, unfavoriteMusic] = createMutation(UnfavoriteMusicMutation);

	const coverImage = createMemo(() => {
		const m = music();
		if (!m) return;
		return new URL(m.coverImage, import.meta.env.VITE_API_BASE).toString();
	});

	const [dragPosition, setDragPosition] = createSignal<number>();
	const dragPercentage = createMemo(() => {
		const position = dragPosition();
		if (!position) return null;
		return position / (untrack(ref)?.clientWidth ?? 1);
	});

	const handleDragEnd = () => {
		const percentage = dragPercentage();
		if (percentage == null) return;
		props.onPlayingChange(false);
		const previousTime = audio.currentTime;
		const currentEl = untrack(audioEl);
		if (currentEl) currentEl.currentTime = audio.duration * percentage;
		props.onPlayingChange(true);
		createRoot((dispose) => {
			createEffect(() => {
				if (audio.currentTime !== previousTime) {
					setDragPosition(undefined);
					dispose();
				}
			});
		});
	};
	const [isPlayerHovered, setIsPlayerHovered] = createSignal(false);
	const makeDragInit =
		(
			commiting: boolean,
			moveEvent: "mousemove" | "touchmove",
			endEvent: "mouseup" | "mouseleave" | "touchend",
			endOnElement = false,
		) =>
		(e: MouseEvent | TouchEvent) => {
			if (!commiting && e.type === "mouseover") {
				return;
			}

			const element = e.currentTarget as HTMLElement;

			const move = (e: MouseEvent | TouchEvent) => {
				if (commiting) {
					const clientX = "touches" in e ? e.touches[0]!.clientX : e.clientX;
					const localX =
						clientX - (untrack(ref)?.getBoundingClientRect().x ?? 0);
					e.preventDefault();
					setDragPosition(localX);
				}
			};
			move(e);

			const end = () => {
				if (commiting) handleDragEnd();
				else setDragPosition(undefined);

				document.removeEventListener(moveEvent, move);
				(endOnElement ? element : document).removeEventListener(endEvent, end);
			};

			document.addEventListener(moveEvent, move, { passive: false });
			(endOnElement ? element : document).addEventListener(endEvent, end);
		};
	const currentPosition = createMemo(() => {
		const dragPos = dragPosition();
		if (dragPos != null) return dragPos;

		const duration = audio.duration || 1;
		const currentTime = audio.currentTime ?? 0;
		return (currentTime / duration) * (untrack(ref)?.clientWidth ?? 0);
	});

	return (
		<div
			ref={setRef}
			class={cn(
				"absolute inset-x-0 bottom-0 z-10 transition-all text-white env-desktop:shadow-tiny",
				music() ? "opacity-100" : "opacity-0",
			)}
			style={{
				height: `calc(var(--safe-area-inset-bottom) + ${
					nav.size.height ?? null
				}px + ${PLAYER_HEIGHT}px)`,
				transform: music() ? "translateY(0)" : `translateY(${PLAYER_HEIGHT}px)`,
			}}
			onMouseEnter={() => setIsPlayerHovered(true)}
			onMouseLeave={() => {
				setIsPlayerHovered(false);
				setDragPosition(undefined);
			}}
		>
			<div class="absolute inset-0 overflow-hidden">
				<Image
					suspend={false}
					src={coverImage()}
					alt=""
					class="absolute -inset-8 object-cover blur-xl brightness-70 min-w-[calc(100%+4rem)] -translate-y-1/2"
				/>
			</div>
			<div
				style={{
					"padding-bottom": `calc(var(--safe-area-inset-bottom) + ${
						nav.size.height ?? null
					}px)`,
				}}
			>
				<Show when={music()}>
					{(music) => (
						<div class="relative p-3 flex justify-between items-center env-desktop:(px-20px py-15px)">
							<div class="flex gap-10px items-center env-desktop:gap-30px">
								<Show when={environment === "desktop"}>
									<div class="flex gap-4px items-center">
										<RawButton
											class="i-bp-skip-prev size-28px transition-transform hover:scale-110"
											aria-label="Previous"
											onClick={() => {
												if (untrack(() => audio.currentTime) < PREV_THRESHOLD) {
													props.onPrev?.();
												} else {
													controls.seek(0);
												}
											}}
										/>
										<RawButton
											class="size-40px flex items-center justify-center transition-transform hover:scale-110"
											aria-label={props.isPlaying ? "Pause" : "Play"}
											onClick={() => props.onPlayingChange(!props.isPlaying)}
										>
											<i
												class={cn(
													"inline-block size-24px",
													props.isPlaying
														? "i-bp-pause-fill"
														: "i-bp-play-fill",
												)}
											/>
										</RawButton>
										<RawButton
											class="i-bp-skip-next size-28px transition-transform hover:scale-110"
											aria-label="Next"
											onClick={() => props.onNext?.()}
										/>
									</div>
									<div class="prose-xs text-center w-75px whitespace-nowrap tabular-nums opacity-80">
										{`${durationFormat.format(
											Temporal.Duration.from({
												seconds: Math.floor(audio.currentTime),
											}).round({ largestUnit: "minute" }),
										)} / ${durationFormat.format(
											Temporal.Duration.from({
												seconds: Math.floor(audio.duration),
											}).round({ largestUnit: "minute" }),
										)}`}
									</div>
								</Show>
								<div class="flex items-center gap-10px env-desktop:gap-12px">
									<Suspense fallback={<div class="size-40px" />}>
										<Image
											src={coverImage()}
											alt={`Album art of ${music().title}`}
											class="size-40px object-cover rounded-4px overflow-hidden"
										/>
									</Suspense>
									<span class="prose-sm text-balance">{music().title?.en}</span>
								</div>
							</div>
							<div class="flex items-center">
								<TrackingWrapper
									trackingEvent={
										music().isFavorite
											? MixpanelEvent.UnfavoriteMusic
											: MixpanelEvent.FavoriteMusic
									}
									trackingData={{
										music_id: music().id,
									}}
								>
									<RawButton
										class="mx-10px flex justify-center items-center rounded-6px env-desktop:size-40px hover:(bg-white/10)"
										aria-label="Add to favorites"
										onClick={() => {
											const mutation = music().isFavorite
												? unfavoriteMusic
												: favoriteMusic;
											// ignore result for now
											mutation({ id: music().id });
										}}
									>
										<i
											class={cn(
												"inline-block size-24px",
												music().isFavorite ? "i-bp-heart-fill" : "i-bp-heart",
											)}
										/>
									</RawButton>
								</TrackingWrapper>
								<Show when={environment === "desktop"}>
									<YoutubeActionButton
										$music={music()}
										environment="desktop"
										desktopButtonClass="hover:bg-white/10"
									/>
								</Show>
								<Show when={environment === "mobile"}>
									<RawButton
										class={cn(
											"inline-block mx-10px size-24px",
											props.isPlaying ? "i-bp-pause" : "i-bp-play",
										)}
										aria-label={props.isPlaying ? "Pause" : "Play"}
										onClick={() => props.onPlayingChange(!props.isPlaying)}
									/>
								</Show>
							</div>
							<div class="absolute inset-x-0 top-0 h-2px bg-white bg-opacity-30 hover:(h-4px top-[-1px])">
								<div
									class="h-16px absolute inset-x-0 top-0 -translate-y-1/2 z-20"
									onMouseDown={makeDragInit(true, "mousemove", "mouseup")}
									onTouchStart={makeDragInit(true, "touchmove", "touchend")}
								/>
								<div
									class="size-full bg-white hover:h-2px"
									style={{
										transform: `translateX(-${
											(1 -
												(dragPercentage() ??
													(audio.currentTime ?? 0) / (audio.duration || 1))) *
											100
										}%)`,
									}}
								/>
								<div
									class={cn(
										"absolute left-0 top-1/2 size-20px rounded-full bg-white shadow pointer-events-none transition-opacity z-15",
										dragPosition() != null || isPlayerHovered()
											? "opacity-100"
											: "opacity-0",
									)}
									style={{
										transform: `translate(calc(${currentPosition()}px - 50%), -50%)`,
									}}
								/>
							</div>
						</div>
					)}
				</Show>
			</div>
		</div>
	);
};
