import { GenericOAuth2 } from "@capacitor-community/generic-oauth2";
import { P, match } from "ts-pattern";
import * as v from "valibot";
import { supabase } from "./supabase";

const GoogleOAuth2Response = v.intersect([
	v.object({
		access_token: v.string(),
	}),
	v.union([
		v.object({ access_token_response: v.object({ id_token: v.string() }) }),
		v.object({ authorization_response: v.object({ id_token: v.string() }) }),
	]),
]);

export const GoogleOAuth2Scopes = {
	Auth: "email profile",
	YouTube: "email https://www.googleapis.com/auth/youtube.readonly",
} as const;

type GoogleOAuth2Scope =
	(typeof GoogleOAuth2Scopes)[keyof typeof GoogleOAuth2Scopes];

export const googleOAuth2 = async (scope: GoogleOAuth2Scope) => {
	const response = await GenericOAuth2.authenticate({
		authorizationBaseUrl: "https://accounts.google.com/o/oauth2/v2/auth",
		accessTokenEndpoint: "https://www.googleapis.com/oauth2/v4/token",
		scope,
		resourceUrl: "https://www.googleapis.com/userinfo/v2/me",
		logsEnabled: true,
		android: {
			appId: import.meta.env.VITE_GOOGLE_OAUTH_ANDROID_CLIENT_ID,
			responseType: "code",
			redirectUrl: "app.backpac.x:/",
		},
		ios: {
			appId: import.meta.env.VITE_GOOGLE_OAUTH_IOS_CLIENT_ID,
			responseType: "code",
			redirectUrl: "app.backpac.x:/",
		},
		web: {
			appId: import.meta.env.VITE_GOOGLE_OAUTH_WEB_CLIENT_ID,
			responseType: "id_token token",
			accessTokenEndpoint: "",
			additionalParameters: {
				nonce: Math.random().toString(),
				prompt: "select_account",
			},
			redirectUrl: new URL("/oauth-callback.htm", location.origin).toString(),
		},
	});
	return v.parse(GoogleOAuth2Response, response);
};

const AppleOAuth2Response = v.object({
	id_token: v.string(),
});

export const appleOAuth2 = async () => {
	const response = await GenericOAuth2.authenticate({
		appId: import.meta.env.VITE_APPLE_OAUTH_APP_ID,
		authorizationBaseUrl: "https://appleid.apple.com/auth/authorize",
	});
	return v.parse(AppleOAuth2Response, response);
};

export const supabaseAuthThroughGoogle = async () => {
	const oauth2Response = await googleOAuth2(GoogleOAuth2Scopes.Auth);
	return supabase.auth.signInWithIdToken({
		provider: "google",
		token: match(oauth2Response)
			.with({ access_token_response: { id_token: P.select() } }, (v) => v)
			.with({ authorization_response: { id_token: P.select() } }, (v) => v)
			.exhaustive(),
		nonce: Math.random().toString(),
		access_token: oauth2Response.access_token,
	});
};

export const supabaseAuthThroughApple = async () => {
	const oauth2Response = await appleOAuth2();
	return supabase.auth.signInWithIdToken({
		provider: "apple",
		token: oauth2Response.id_token,
	});
};
