import { Tooltip as DashboardTooltip, type FontSpec } from "chart.js";
import { on } from "solid-js";
import {
	createEffect,
	createMemo,
	createSignal,
	onCleanup,
	onMount,
} from "solid-js";
import { Intl, type Temporal } from "temporal-polyfill";
import { Chart } from "~/lib/chart";
import { getColor } from "~/lib/colors";
import { environment } from "~/lib/environment";
import { cn } from "~/lib/utils";

type PlainDateRange = {
	from: Temporal.PlainDate;
	to: Temporal.PlainDate;
};

const durationFormat = Intl.DateTimeFormat("en", {
	month: "short",
	day: "numeric",
});

type MetricChartProps = {
	range: PlainDateRange;
	data: number[];
	class?: string;
};

export const MetricChart = (props: MetricChartProps) => {
	let ref: HTMLCanvasElement | undefined;
	const [chart, setChart] = createSignal<Chart | undefined>(undefined);

	const data = createMemo(() => {
		return {
			labels: props.data.map((_, i) =>
				durationFormat.format(props.range.from.add({ days: i })),
			),
			datasets: [{ data: [...props.data] }],
		};
	});

	const chartFont: Partial<FontSpec> = {
		family: "Inter Variable",
		size: 13,
		weight: "normal",
		lineHeight: 1.2,
	};

	onMount(() => {
		const initChart = () => {
			if (!ref?.ownerDocument?.defaultView) return;
			const chart = new Chart(ref, {
				type: "line",
				data: data(),
				options: {
					clip: false,
					// @ts-expect-error: why is this not in the types?
					pointRadius: environment === "mobile" ? 2 : 4,
					borderColor: getColor("primary", "raw"),
					borderWidth: 2,
					responsive: true,
					maintainAspectRatio: false,
					backgroundColor: getColor("brand-lime", "raw"),
					interaction: {
						intersect: false,
						mode: "index",
					},
					scales: {
						x: {
							ticks: {
								source: "labels",
								callback(value, index, ticks) {
									if (
										index !== 0 &&
										index !== ticks.length - 1 &&
										environment === "mobile"
									)
										return "";

									return this.getLabelForValue(index);
								},
								color:
									environment === "desktop"
										? getColor("tertiary", "raw")
										: undefined,
							},
							grid:
								environment === "desktop"
									? {
											drawOnChartArea: false,
											color: "#000",
										}
									: {
											drawTicks: false,
										},
							border: {
								width: 1,
								color: getColor("primary", "raw"),
							},
						},

						y: {
							min: 0,
							grid: {
								lineWidth: 1,
								color: getColor("border-dark", "raw"),
								drawTicks: false,
							},
							ticks: {
								callback: (value, index, _ticks) => {
									if (index % 2 !== 0) return;
									return value;
								},
								padding: 10,
							},
							axis: "y",
							border:
								environment === "desktop"
									? {
											dash: [2, 2],
											dashOffset: 0,
											display: false,
										}
									: {},
						},
					},
					animation: {
						duration: 500,
					},
					plugins: {
						tooltip: {
							position: "nearest",
							displayColors: false,
							backgroundColor: "rgb(0 0 0 / 0.7)",
							titleFont: chartFont,
							bodyFont: chartFont,
						},
						customCanvasBackgroundColor: {
							color: environment === "desktop" ? "white" : "transparent",
						},
					},
					...(environment === "desktop"
						? {
								layout: {
									padding: {
										top: 40,
										bottom: 40,
										left: 28,
										right: 28,
									},
								},
							}
						: {}),
				},
				plugins: [DashboardTooltip],
			});
			setChart(chart);
		};
		setTimeout(initChart, 1000);
	});

	createEffect(
		on(
			() => data(),
			() => {
				const c = chart();
				if (!c) return;
				c.data.labels = data().labels;
				for (let i = 0; i < c.data.datasets.length; i++) {
					if (!data().datasets[i]) break;
					c.data.datasets[i]!.data = data().datasets[i]!.data;
				}
				c.update();
			},
			{ defer: true },
		),
	);

	onCleanup(() => chart()?.destroy());

	return <canvas ref={ref} class={cn("h-full", props.class)} />;
};
