import mixpanel from "mixpanel-browser";
import { type JSX, children, createEffect, onCleanup } from "solid-js";

export enum MixpanelEvent {
	SignUp = "Sign Up",
	SignIn = "Sign In",
	SignOut = "Sign Out",
	ChannelAdded = "Channel Added",
	ChannelDeleted = "Channel Deleted",
	UpdatePayoutDetails = "Update Payout Details",
	PlayMusic = "Play Music",
	StopMusic = "Stop Music",
	FavoriteMusic = "Favorite Music",
	UnfavoriteMusic = "Unfavorite Music",
}

// TODO: manage the properties of each events more systematically
export function trackEvent(
	event: MixpanelEvent,
	data: Record<string, unknown>,
) {
	// @ts-expect-error mixpanel.__loaded is not typed
	if (!mixpanel.__loaded) return;
	mixpanel.track(event, data);
}

interface TrackingWrapperProps {
	trackingEvent: MixpanelEvent;
	trackingData?: Record<string, unknown>;
	children: JSX.Element;
}

export function TrackingWrapper(props: TrackingWrapperProps) {
	const c = children(() => props.children);
	const handleClick = () => {
		trackEvent(props.trackingEvent, props.trackingData ?? {});
	};

	createEffect(() => {
		const children = c();

		const attachListener = (node: unknown) => {
			if (node instanceof Element) {
				const element = node;
				element.addEventListener("click", handleClick);
				onCleanup(() => element.removeEventListener("click", handleClick));
			}
		};

		if (Array.isArray(children)) children.forEach(attachListener);
		else attachListener(children);
	});

	return <>{c()}</>;
}
