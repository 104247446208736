import { For, Match, Switch, createMemo } from "solid-js";
import { Temporal } from "temporal-polyfill";
import { createDeferredValue } from "~/lib/createDeferredValue";
import { createToeQuery } from "~/lib/gql/createToeQuery";
import { graphql } from "~/lib/gql/tada";
import { latest } from "~/lib/latest";
import { ChannelContentCard, ChannelVideoItemFragment } from "./contentCard";

// TODO: add pagination and sorting
const ChannelVideoQuery = graphql.persisted(
	"sha256:2f1090400f3312c40acb71965a524f0eaef48a799e31fc51885abbff54e588f4",
	graphql(
		`
		query ChannelVideos(
				$channelId: ID!
				$filter: ChannelMetricsFilter!
			) {
			node(id: $channelId) {
				__typename
				id
				... on Channel {
					videos(filter: $filter) {
						pageInfo {
							hasNextPage
							hasPreviousPage
						}
						edges {
							node {
								...ChannelVideoItem
							}
							cursor
						}
					}	
				}
			}
		}
	`,
		[ChannelVideoItemFragment],
	),
);

type ContentsAnalyticsProps = {
	selectedChannelId?: string;
	selectedDateRange: [Temporal.PlainDate, Temporal.PlainDate] | undefined;
};

export const ContentAnalyticsContainer = (props: ContentsAnalyticsProps) => {
	const metricFilters = createDeferredValue(
		createMemo(() =>
			props.selectedDateRange
				? {
						currentFilter: {
							range: {
								from: props.selectedDateRange[0].toString(),
								to: props.selectedDateRange[1].toString(),
							},
						},
						// Note: This is a hacky workaround to bypass that previousFilter is required.
						// TODO: Previous Filter should be optional.
						previousFilter: {
							range: {
								// workaround to avoid unnecessary queries
								from: Temporal.Now.plainDateISO().add({ days: 1 }).toString(),
								to: Temporal.Now.plainDateISO().add({ days: 1 }).toString(),
							},
						},
					}
				: {
						currentFilter: {
							range: {
								from: Temporal.Now.plainDateISO()
									.subtract({ days: 7 })
									.toString(),
								to: Temporal.Now.plainDateISO().toString(),
							},
						},
					},
		),
	);

	const [videoQuery] = createToeQuery({
		query: ChannelVideoQuery,
		variables: () => ({
			channelId: props.selectedChannelId!,
			filter: metricFilters()?.currentFilter,
		}),
		pause: () => !props.selectedChannelId,
	});

	const videos = createMemo(() => {
		const data = latest(() => videoQuery.data);
		if (data && data.node?.__typename === "Channel") {
			return data.node.videos?.edges || [];
		}
		return [];
	});

	return (
		<div class="flex flex-col gap-4">
			<div class="flex flex-row">
				<h2 class="prose-lg-b">Your posts</h2>
			</div>
			<div class="flex flex-wrap gap-20px">
				<Switch>
					<Match when={videos().length === 0}>
						<div class="flex flex-col items-center w-full mt-108px">
							<i class="i-bp-videos size-32px" />
							<span class="prose-md-b mt-10px">No Posts</span>
							<span class="prose-sm text-tertiary leading-[1.3] mt-4px">
								Your posts haven't received an increase in views
							</span>
						</div>
					</Match>
					<Match when={videos().length > 0}>
						{/* TODO: Replace this with virtualizer */}
						<For each={videos()}>
							{(edge) => (
								<ChannelContentCard
									$video={edge.node}
									class="env-desktop:max-w-346px w-100%"
								/>
							)}
						</For>
					</Match>
				</Switch>
			</div>
		</div>
	);
};
