// @refresh reload
import "solid-devtools";
import { Capacitor } from "@capacitor/core";
import * as LiveUpdates from "@capacitor/live-updates";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import * as Sentry from "@sentry/capacitor";
import * as SentrySolid from "@sentry/solidstart";
import { StartClient, mount } from "@solidjs/start/client";
import { supabaseIntegration } from "@supabase/sentry-js-integration";
import { SafeArea, type SafeAreaInsets } from "capacitor-plugin-safe-area";
import mixpanel from "mixpanel-browser";
import { environment } from "./lib/environment";
import { initSession, supabase } from "./lib/supabase";

function _initMixpanel() {
	// Only initialize Mixpanel in production
	if (!import.meta.env.PROD) {
		return;
	}

	mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
		debug: import.meta.env.MODE === "development",
		track_pageview: "url-with-path",
	});
	supabase.auth.onAuthStateChange((_, session) => {
		if (session?.user) {
			mixpanel.identify(session.user.id);
			mixpanel.people.set({
				email: session.user.email,
				username: session.user.user_metadata.name,
			});
		} else {
			mixpanel.reset();
		}
	});
}

function _initSentry() {
	// Only initialize Sentry in production
	if (!import.meta.env.PROD) {
		return;
	}
	Sentry.init(
		{
			dsn: import.meta.env.VITE_SENTRY_DSN,
			environment: `${import.meta.env.MODE}-${environment}`,
			integrations: [
				supabaseIntegration(supabase, SentrySolid, {
					tracing: true,
					breadcrumbs: true,
					errors: true,
				}),
			],
			tracesSampleRate: 1.0, //  Capture 100% of the transactions
			maxBreadcrumbs: 50,
			// Note: This is only available in paid plans
			// replaysSessionSampleRate: 0.1,
			// replaysOnErrorSampleRate: 1.0,
		},
		SentrySolid.init,
	);

	supabase.auth.onAuthStateChange((_, session) => {
		if (session?.user) {
			Sentry.setUser({
				id: session.user.id,
				email: session.user.email,
				username: session.user.user_metadata.name,
			});
		} else {
			Sentry.setUser(null); // Clear user context when logged out
		}
	});
}

async function initApp() {
	_initMixpanel();
	_initSentry();
	const supabaseAuthPromise = supabase.auth
		.initialize()
		.then(() => initSession());
	const liveUpdatePromise = enableLiveUpdates();
	const mockingPromise = enableMocking();

	await Promise.all([supabaseAuthPromise, liveUpdatePromise, mockingPromise]);
	if (Capacitor.getPlatform() === "android") {
		// wait for splash screen to hide and finish screen settings change
		await new Promise((resolve) => setTimeout(resolve, 400));
	}
	await setupSafeArea();
}

async function enableMocking() {
	if (import.meta.env.VITE_ENABLE_MOCKING == null) return;

	const { worker } = await import("./mocks/browser");
	return worker.start();
}

// TODO: move to a less offensive update strategy
async function enableLiveUpdates() {
	try {
		if (!import.meta.env.PROD || !Capacitor.isNativePlatform()) return;

		const result = await LiveUpdates.sync();
		if (result.activeApplicationPathChanged) {
			await LiveUpdates.reload();
		}
	} finally {
		await SplashScreen.hide();
	}
}

async function setupSafeArea() {
	if (Capacitor.isNativePlatform()) {
		const applyInsets = ({ insets }: SafeAreaInsets) => {
			for (const [key, value] of Object.entries(insets)) {
				document.documentElement.style.setProperty(
					`--safe-area-inset-${key}`,
					`${value}px`,
				);
			}
		};
		SafeArea.addListener("safeAreaChanged", applyInsets);
		await Promise.allSettled([
			StatusBar.setOverlaysWebView({ overlay: true }),
			StatusBar.setStyle({ style: Style.Light }),
		]);
		await SafeArea.getSafeAreaInsets().then(applyInsets);
	} else {
		for (const direction of ["top", "bottom", "left", "right"]) {
			document.documentElement.style.setProperty(
				`--safe-area-inset-${direction}`,
				`env(safe-area-inset-${direction})`,
			);
		}
	}
}

initApp().then(() =>
	mount(() => <StartClient />, document.getElementById("app")!),
);
