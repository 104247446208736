import { AppScreen } from "@contentstech/stackflow-plugin-basic-ui/solid";
import { useChild } from "@contentstech/stackflow-plugin-omniflow";
import {
	type ActivityComponentType,
	useFlow,
} from "@contentstech/stackflow-solid/future";
import { Button as RawButton } from "@kobalte/core/button";
import { createElementSize } from "@solid-primitives/resize-observer";
import type { RegisteredActivityName } from "@stackflow/config";
import { For, Show, createContext, createSignal, useContext } from "solid-js";
import { Dynamic } from "solid-js/web";
import logoDesktop from "~/assets/logo-desktop.png";
import { ErrorBoundary } from "~/components/errorBoundary";
import { Image } from "~/components/ui/image";
import { WebLink } from "~/components/webLink";
import {
	FEEDBACK_FORM_LINK,
	HELP_CENTER_ROOT_URL,
	PRIVACY_POLICY_URL,
	TERMS_OF_SERVICE_URL,
} from "~/lib/constants/urls";
import { environment } from "~/lib/environment";
import { cn } from "~/lib/utils";

declare module "@stackflow/config" {
	interface Register {
		RootActivity: {
			OMNI_childName?: RegisteredActivityName;
		};
	}
}

type NavProps<ActivityName extends RegisteredActivityName> = {
	pages: (
		| ({
				label: string;
				icon: string;
		  } & (
				| {
						id: ActivityName;
						href?: never;
				  }
				| {
						href: string;
						id?: never;
				  }
		  ))
		| null
	)[];
};

const Context = createContext({
	size: {
		width: 0 as number | null,
		height: 0 as number | null,
	},
});
export const useNavContext = () => useContext(Context);

export const Nav = <PageId extends RegisteredActivityName>(
	props: NavProps<PageId>,
) => {
	const [nav, setNav] = createSignal<HTMLElement>();
	const size = createElementSize(nav);
	const child = useChild();
	const actions = useFlow();

	return (
		<Context.Provider
			value={{
				size: environment === "mobile" ? size : { width: 0, height: 0 },
			}}
		>
			<div
				class="relative flex-1 bg-background env-desktop:(flex flex-row-reverse rounded-t-12px overflow-hidden)"
				aria-label="Main Navigation"
			>
				<main
					class="relative overflow-auto no-scrollbar env-mobile:(max-h-100dvh size-full) env-desktop:(flex-1)"
					style={{
						"padding-bottom":
							environment === "mobile"
								? `calc(var(--safe-area-inset-bottom) + ${20 + (size.height ?? 0)}px)`
								: "60px",
					}}
				>
					<ErrorBoundary>{child?.()?.render()}</ErrorBoundary>
				</main>
				<nav class="flex flex-col z-10 env-mobile:(absolute inset-x-0 bottom-0 bg-primary rounded-t-6) env-desktop:(inset-y-0 left-0 bg-white px-12px py-20px w-240px border-r border-border-light)">
					<div ref={setNav} class="flex env-desktop:(flex-col gap-2px)">
						<For each={props.pages}>
							{(page) => (
								<Show
									when={page}
									fallback={
										<hr class="my-12px w-full border-t border-border-light" />
									}
								>
									{(page) => (
										<Dynamic
											component={page().id ? RawButton : WebLink}
											href={page().href}
											onClick={() => {
												if (page().id) {
													actions.replace(
														page().id as RegisteredActivityName,
														{},
													);
												}
											}}
											class={cn(
												"flex-1 flex items-center justify-center group env-mobile:(flex-col gap-1 my-2) env-desktop:(rounded-6px px-12px py-10px gap-16px hover:bg-background-dark)",
												child?.()?.name === page().id &&
													"env-desktop:bg-background-dark",
											)}
										>
											<div class="size-6 relative">
												<div
													class={cn(
														"absolute inset-0 z-1 transition-opacity duration-50 env-mobile:bg-highlighted ",
														child?.()?.name === page().id
															? "opacity-100"
															: "opacity-0",
														page().icon,
													)}
												/>
												<div
													class={cn(
														"absolute inset-0 env-mobile:bg-tertiary env-desktop:bg-primary",
														page().icon,
													)}
												/>
											</div>
											<div
												class="font-medium transition-colors duration-50 flex-1 env-mobile:(text-tertiary text-10px) env-desktop:(text-primary text-left text-15px)"
												classList={{
													"!env-mobile:text-brand-lime":
														child?.()?.name === page().id,
												}}
											>
												{page().label}
											</div>
										</Dynamic>
									)}
								</Show>
							)}
						</For>
					</div>
					<Show when={environment === "desktop"}>
						<div class="flex-1" />
						{/* TODO:: Remove the comment after launching the app */}
						{/* <Image src={appPromotion} alt="Mobile app download link" /> */}
						<div class="border-t border-border-light my-16px" />
						<div class="flex flex-col gap-10px px-10px">
							{[
								{
									label: "Terms of Service",
									href: TERMS_OF_SERVICE_URL,
								},
								{
									label: "Privacy Policy",
									href: PRIVACY_POLICY_URL,
								},
								{ label: "© 2024 BackPac" },
							].map(({ label, href }) => (
								<WebLink href={href} class="prose-xs text-left text-secondary">
									{label}
								</WebLink>
							))}
						</div>
					</Show>
					<div class="env-mobile:h-[var(--safe-area-inset-bottom)]" />
				</nav>
			</div>
		</Context.Provider>
	);
};

const RootActivity: ActivityComponentType<"RootActivity"> = (props) => {
	const actions = useFlow();

	return (
		<AppScreen>
			<div class="flex flex-col size-full bg-primary">
				<Show when={environment === "desktop"}>
					<header class="flex px-24px py-18px items-center justify-between bg-primary">
						<WebLink href="/" target="_self">
							<Image src={logoDesktop} alt="BackPac" class="h-28px" />
						</WebLink>
						<div class="flex items-center gap-24px">
							<WebLink
								class="px-4 py-6px -my-2 flex items-center gap-6px text-white prose-xs-b rounded-full border-2 border-border-dark"
								href={HELP_CENTER_ROOT_URL}
							>
								Guide
								<i class="inline-block i-bp-next text-white" />
							</WebLink>
						</div>
					</header>
				</Show>
				<Nav
					pages={[
						{
							id: "DashboardActivity",
							label: "Dashboard",
							icon: "mask-dashboard",
						},
						{
							id: "MusicActivity",
							label: "Music",
							icon: "mask-music",
						},
						{
							id: "PayoutActivity",
							label: "Payout",
							icon: "mask-payout",
						},
						...(environment === "desktop"
							? ([
									null,
									{
										id: "ChannelsSettingsActivity",
										label: "Manage channel",
										icon: "i-bp-channel-bold",
									},
									{
										id: "SettingsActivity",
										label: "Settings",
										icon: "i-bp-settings size-24px",
									},
									{
										label: "Feedback",
										icon: "i-bp-email",
										href: FEEDBACK_FORM_LINK,
									},
								] as const)
							: ([
									{
										id: "ProfileActivity",
										label: "Profile",
										icon: "mask-profile",
									},
								] as const)),
					]}
				/>
			</div>
		</AppScreen>
	);
};

export default RootActivity;
